import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ModalBaseSm = ({ isOpen, onClose, title, childComponent }) => {
    const backdropStyle = 'static';
    const modalStyle={};
    return (
        <Modal

            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            size='sm'
            zIndex={9999}
            style={modalStyle}
            centered
        >
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                {childComponent}
            </ModalBody>
        </Modal >
    )
}

export default ModalBaseSm
