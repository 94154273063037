import React from 'react'
import { Container } from 'reactstrap';
import Barra from '../Modulos/Barra';
import BarraPrincipal from '../Modulos/BarraPrincipal';

const Principal = () => {
    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <div className="layout-page">
                        <BarraPrincipal />
                        <div className="img-wrapper">
                            <img src='./WebInicio.png' className="background-img" />
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .img-wrapper {
                    position: relative;
                    width: 100%;
                    height: calc(100vh - 62px);
                    overflow: hidden;
                }

                .background-img {
                    position: absolute;/* Offset by the height of the navbar */
                    left: 0;
                    width: 100% ;
                    height: 100%;
                    object-fit: cover; /* Ensures the image covers the area without stretching */
                    z-index: -1; /* Ensures it stays behind other content */
                    
                }
            `}</style>
        </>

    )
}

export default Principal
