import React from 'react';
import BarraPrincipal from '../Modulos/BarraPrincipal';

const MyProfile = () => {
    const userInfo = {
        userName: 'Juan Castilla',
        email: 'jmcastilla@example.com',
        avatar: '../assets/img/avatars/1.png',
        empresasAsociadas: [
            'Cargotronics',
            'Empresa Alpha',
            'Logística Beta',
        ],
        otherInfo: {
            role: 'Administrador',
            joinedDate: '2022-01-15',
            status: 'Activo',
        },
        notifications: [
            'Nueva tarea asignada.',
            'Revisión requerida para el proyecto.',
            'Notificación de sistema.',
        ],
    };

    const styles = {
        container: {
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '20px',
        },
        userCard: {
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            gridColumn: '1 / -1',
        },
        avatarContainer: {
            position: 'relative',
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        avatar: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        // onlineIndicator: {
        //     position: 'absolute',
        //     bottom: '5px', // Ajuste desde la parte inferior del contenedor
        //     right: '5px', // Ajuste desde la parte derecha del contenedor
        //     width: '20px',
        //     height: '20px',
        //     backgroundColor: '#4caf50',
        //     borderRadius: '50%',
        //     border: '2px solid #fff', // Borde blanco para mejor visibilidad
        // },
        userDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
        userName: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#333',
        },
        userEmail: {
            fontSize: '16px',
            color: '#666',
        },
        userInfoExtra: {
            marginTop: '10px',
            fontSize: '14px',
            color: '#555',
        },
        smallCardContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
        },
        smallCard: {
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
        },
        cardHeader: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '15px',
            borderBottom: '2px solid #ddd',
            paddingBottom: '10px',
            color: '#333',
        },
        list: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
        listItem: {
            padding: '10px 0',
            borderBottom: '1px solid #eee',
            fontSize: '14px',
            color: '#444',
        },
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <BarraPrincipal />
                    <div style={styles.container}>
                        {/* Tarjeta Principal: Información del Usuario */}
                        <div style={styles.userCard}>
                            <div style={styles.avatarContainer}>
                                <img
                                    src={userInfo.avatar}
                                    alt="Avatar"
                                    style={styles.avatar}
                                />
                                {/* <div style={styles.onlineIndicator}></div> */}
                            </div>

                            <div style={styles.userDetails}>
                                <span style={styles.userName}>
                                    {userInfo.userName}
                                </span>
                                <span style={styles.userEmail}>
                                    {userInfo.email}
                                </span>
                                <div style={styles.userInfoExtra}>
                                    <p><strong>Rol:</strong> {userInfo.otherInfo.role}</p>
                                    <p><strong>Estado:</strong> {userInfo.otherInfo.status}</p>
                                    <p><strong>Fecha de Ingreso:</strong> {userInfo.otherInfo.joinedDate}</p>
                                </div>
                            </div>
                        </div>

                        {/* Tarjetas Secundarias */}
                        <div style={styles.smallCardContainer}>
                            {/* Empresas Asociadas */}
                            <div style={styles.smallCard}>
                                <div style={styles.cardHeader}>Empresas Asociadas</div>
                                <ul style={styles.list}>
                                    {userInfo.empresasAsociadas.map((empresa, index) => (
                                        <li key={index} style={styles.listItem}>
                                            {empresa}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Notificaciones */}
                            <div style={styles.smallCard}>
                                <div style={styles.cardHeader}>Notificaciones</div>
                                <ul style={styles.list}>
                                    {userInfo.notifications.map((notification, index) => (
                                        <li key={index} style={styles.listItem}>
                                            {notification}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
