import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import TooltipComponent from '../../componentes/radix/Tooltip';
import SwitchButton from '../../componentes/SwitchButton';
import axios from '../../utils/axiosConfig';
import ModalBaseSm from '../modals/modal-base-sm';
import FormAddNewIpFija from './formAddNewIpFija';
import Swal from 'sweetalert2';
import SwitchButton2 from '../../componentes/SwithButton2';

const FormAddNewUser = ({ onClose, refreshUsers }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [trafico, setTrafico] = useState(false);
    const [inventarios, setInventarios] = useState(false);
    const [geocercas, setGeocercas] = useState(false);
    const [rutas, setRutas] = useState(false);
    const [comandos, setComandos] = useState(false);
    const [ipFija, setIpFija] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [listProyectos, setListProyectos] = useState([]);
    const [listEmpresas, setListEmpresas] = useState([]);
    const [listEmpresasFijas, setListEmpresasFijas] = useState([]);
    const [listIps, setListIps] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    const [listRolesTrafico, setListRolesTrafico] = useState([]);
    const [infoUpdateUser, setInfoUpdateUser] = useState(null);

    const [modalOpenBaseSm, setModalOpenBaseSm] = useState(false);

    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [nickName, setNickName] = useState("");
    const [password, setPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const [selectedProyect, setSelectedProyect] = useState("");
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [selectedEmpresasAsocArray, setSelectedEmpresasAsocArray] = useState([]);
    const [selectedEmpresaAsoc, setSelectedEmpresaAsoc] = useState("");
    const [selectedEmpresaFija, setSelectedEmpresaFija] = useState(2);
    const [selectedRole, setSelectedRole] = useState(0);
    const [selectedRoleT, setSelectedRoleT] = useState(0);
    const [selectedIpFija, setSelectedIpFija] = useState(0);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [jsonIds, setJsonIds] = useState("");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //onclose Modal Base Sm
    const onCloseModalBaseSm = () => {
        setModalOpenBaseSm(false);
    }

    // Manejadores de eventos para los SwitchButtons (Checkbox)
    const handleCheckboxChangeTrafico = (checked) => {
        setTrafico(checked);
    };

    const handleCheckboxChangeInventario = (checked) => {
        setInventarios(checked);
    };

    const handleCheckboxChangeGeocerca = (checked) => {
        setGeocercas(checked);
    };

    const handleCheckboxChangeRuta = (checked) => {
        setRutas(checked);
    };

    const handleCheckboxChangeComando = (checked) => {
        setComandos(checked);
    };

    const handleCheckboxChangeIpFija = (checked) => {
        setIpFija(checked);
    };

    // Listar proyectos
    const fetchProyectosList = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_SERVER + '/usuarios/getlistaproyectos', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTPROYECTOS", res.data.data);
                setListProyectos(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };


    // Listar empresas por proyecto
    const fetchEmpresasList = async (proyectoId) => {
        const data = { proyecto: proyectoId };
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getlistaempresa', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTEMPRESAS", res.data.data);
                setListEmpresas(res.data.data);
                if (res.data.data.length === 1) {
                    setSelectedEmpresa(res.data.data[0].IdEmpresa);
                }
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Listar empresas fijas por proyecto
    const fetchEmpresasFijasList = async (proyectoId) => {
        const data = { proyecto: proyectoId };
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getlistaempresasFijaInventario', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTEMPRESASFIJAS", res.data.data);
                setListEmpresasFijas(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Listar IPs por proyecto
    const fetchIpsList = async (proyectoId) => {
        const data = { proyecto: proyectoId };
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getlistaIps', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTIPLIST", res.data.data);
                setListIps(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Listar roles
    const fetchRolesList = async (proyectoId) => {
        const data = { proyecto: proyectoId };
        console.log(data);
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getlistaRolusuarios', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTROLES", res.data.data);
                setListRoles(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    }
    // Listar roles TRAFICO
    const fetchRolesTraficoList = async (proyectoId) => {
        const data = { proyecto: proyectoId };
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getlistaRolTrafico', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                console.log("LISTROLESTRAFICO", res.data.data);
                setListRolesTrafico(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    }


    const editUser = async (username) => {
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getobtenerusuario', {
                username,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                setInfoUpdateUser(res.data.data);
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };



    const insertUser = async (data) => {
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/insertusuario', data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                onClose();
                refreshUsers();
                Swal.fire({
                    title: '¡Éxito al crear usuario!',
                    text: 'Usuario creado y almacenado exitosamente',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar',
                });
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const empresaSeleccionada = listEmpresasFijas && listEmpresasFijas.find(empresaF => empresaF.IdEmpresa === 2);

    //HNADLES
    const handleSelectIdProyecto = (e) => {
        setSelectedProyect(e.target.value);
    }
    const handleSelectIdEmpresa = (e) => {
        setSelectedEmpresa(e.target.value);
    }

    const handleSelectIdRole = (e) => {
        setSelectedRole(e.target.value);
    }
    const handleSelectIdRoleT = (e) => {
        setSelectedRoleT(e.target.value);
    }
    const handleSelectIdEmpresaAsoc = (event) => {
        const selectedId = parseInt(event.target.value);
        console.log("Empresa asoc seleccionadaa", selectedId);
        console.log("Listar empresas del estado por proyecto", listEmpresas);
        const selectedEmpresa = listEmpresas.find(
            (empresa) => empresa.IdEmpresa === selectedId
        );
        const selectedName = selectedEmpresa?.NombreEmpresa; // Asegúrate de extraer `NombreEmpresa`
        console.log("Empresa asoc seleccionada nombre", selectedName);

        if (
            selectedName &&
            !selectedEmpresasAsocArray.some((empresa) => empresa.id === selectedId)
        ) {
            setSelectedEmpresasAsocArray([
                ...selectedEmpresasAsocArray,
                { id: selectedId, name: selectedName },
            ]);
        }

        setSelectedEmpresaAsoc(selectedId);
    };

    const createEmpresaIdsObject = (empresasAsociadas) => {
        if (!empresasAsociadas || empresasAsociadas.length === 0) {
            return "";
        }

        return empresasAsociadas.map((empresa) => empresa.id).join(",");
    };



    const handleRemoveEmpresa = (id) => {
        setSelectedEmpresasAsocArray(selectedEmpresasAsocArray.filter((empresa) => empresa.id !== id));
    };

    const handleSelectIpFija = (e) => {
        console.log("Valor de ip Fija select: ", e.target.value);
        setSelectedIpFija(e.target.value);
        if (e.target.value === 'AGREGAR' && selectedProyect) {
            setModalOpenBaseSm(true);
        }
    }

    const onSubmit = () => {
        const data = {
            NombreCompleto: nombre,
            CorreoUsers: correo,
            IdUser: nickName,
            Pwd: password,
            FKProyecto: selectedProyect,
            FKICEmpresa: selectedEmpresa,
            EmpresaInventario: selectedEmpresaFija,
            tipouser: selectedRole,
            RolTrafico: selectedRoleT,
            Trafico: trafico,
            Inventario: inventarios,
            Geocerca: geocercas,
            CreacionRutas: rutas,
            comando: comandos,
            ipfija: ipFija,
            FKIp: selectedIpFija,
            EmpresasTrafico: jsonIds,
        }
        console.log(data);
        const missingFields = [];

        if (!nombre) missingFields.push("Nombre");
        if (!correo) missingFields.push("Correo");
        if (!selectedRole) missingFields.push("Rol");
        if (!password) missingFields.push("Contraseña");
        if (!selectedProyect) missingFields.push("Proyecto");
        if (!selectedEmpresa) missingFields.push("Empresa");

        if (missingFields.length > 0) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `Debe llenar los siguientes campos obligatorios: ${missingFields.join(", ")}.`,
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        }
        else {
            insertUser(data);
        }
    }

    useEffect(() => {
        fetchProyectosList();
    }, []);

    useEffect(() => {
        fetchEmpresasList(selectedProyect);
        fetchEmpresasFijasList(selectedProyect);
        fetchIpsList(selectedProyect);
        fetchRolesList(selectedProyect);
        fetchRolesTraficoList(selectedProyect);
    }, [selectedProyect]);

    useEffect(() => {
        if (selectedEmpresasAsocArray.length > 0) {
            console.log("Estado empresas asocidas array: ", selectedEmpresasAsocArray);
            const empresaIdsObject = createEmpresaIdsObject(selectedEmpresasAsocArray);
            console.log("empresaIdsObject", empresaIdsObject);
            setJsonIds(empresaIdsObject);
        } else {
            setDropdownVisible(false);
        }
    }, [selectedEmpresasAsocArray]);


    const styles = {
        columnaClass: {
            paddingRight: isMobile ? '25px' : '40px',
            paddingLeft: isMobile ? '0px' : '40px',
        },
        inputClass: {
            border: '1px solid #000000',
        },
        Label: {
            color: '#000000',
        },
        otrosRolesContainer: {
            padding: '10px',
            marginTop: '20px',
            backgroundColor: '#dce6eb',
            width: 'fit-content',
        },
        otrosRolesFieldset: {
            border: '1px solid black',
            padding: '10px',
            margin: 0,
            width: '300px',
            backgroundColor: '#dce6eb',
        },
        otrosRolesLegend: {
            fontWeight: 'normal',
            fontSize: '14px',
            padding: '0 5px',
            color: 'black',
            backgroundColor: '#dce6eb',
            border: '1px solid black',
            marginLeft: '10px',
            marginTop: '-10px',
            position: 'relative',
        },
        dropdownContainer: {
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 5,
            padding: '10px',
            zIndex: 1000,
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
        },
        customScroll: {
            scrollbarWidth: 'thin', // Firefox
            scrollbarColor: '#ccc #f1f1f1', // Gris claro en el scroll
        },
        webkitScroll: {
            '&::-webkit-scrollbar': {
                width: '8px', // Ancho del scroll
            },
            '&::-webkit-scrollbar-track': {
                background: '#f9f9f9', // Fondo más claro para la pista
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc', // Gris claro para la barra de desplazamiento
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#aaa', // Gris intermedio al hacer hover
            },
            '&::-webkit-scrollbar-button': {
                display: 'none', // Ocultar botones de índice
            },
        },
        empresaRow: {
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '10px 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#f9f9f9',
            width: '100%',
            transition: 'background-color 0.3s ease',
            marginBottom: '5px',
        },
        empresaRowHover: {
            backgroundColor: '#e6f7ff',
        },
        empresaName: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        removeIcon: {
            fontSize: '18px',
            color: 'red',
            cursor: 'pointer',
        },
    };
    return (
        <>
            <MDBRow style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <MDBCol md={6}>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Nombre</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" id="name" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Correo</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" type='email' id="name" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">NickName</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" id="name" value={nickName} onChange={(e) => setNickName(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Password</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <input
                                    className="Input"
                                    type={showPassword ? 'text' : 'password'}  // Alterna entre 'password' y 'text'
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ paddingRight: '67px' }}  // Añade espacio para el icono
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(226px, -4px)',
                                        cursor: 'pointer',
                                        color: '#999',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className='bx bx-hide' style={{ fontSize: '20px' }}></i> // Icono de ocultar contraseña
                                    ) : (
                                        <i className='bx bx-show' style={{ fontSize: '20px' }}></i> // Icono de mostrar contraseña
                                    )}
                                </span>
                                {/* <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(220px, -2px)',
                                        cursor: 'pointer',
                                        color: '#999',
                                    }}
                                >
                                   <SwitchButton2 checked={updatePassword} onChange={handleCheckboxChangeGeocerca} type={''} />
                                </span> */}
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Proyecto</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>

                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectIdProyecto} value={selectedProyect || ""}>
                                <option value="" disabled>
                                    Selecciona un proyecto
                                </option>
                                {listProyectos?.map((proyecto) => (
                                    <option key={proyecto.IDProyecto} value={proyecto.IDProyecto}>
                                        {proyecto.Descripcion}
                                    </option>
                                ))}

                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Empresa</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>

                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectIdEmpresa} value={selectedEmpresa || ""}>
                                {listEmpresas && listEmpresas?.map((empresa) => (
                                    <option key={empresa.IdEmpresa} value={empresa.IdEmpresa}>
                                        {empresa.NombreEmpresa}
                                    </option>
                                ))}

                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Empresa Fija</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>

                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" defaultValue={2} value={selectedEmpresaFija || ""} disabled>
                                {listEmpresasFijas && listEmpresasFijas.length === 0 ? (
                                    <option value="2">-NINGUNA-</option>
                                ) : (
                                    empresaSeleccionada ? (
                                        <option key={empresaSeleccionada.IdEmpresa} value={empresaSeleccionada.IdEmpresa} >
                                            {empresaSeleccionada.NombreEmpresa}
                                        </option>
                                    ) : (
                                        <option value="2">-NINGUNA-</option>
                                    ))}

                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Rol User</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>

                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectIdRole} value={selectedRole || ""}>
                                <option value="" disabled>
                                    Selecciona un rol
                                </option>
                                {listRoles && listRoles?.map((roles) => (
                                    <option key={roles.IDRol} value={roles.IDRol}>
                                        {roles.NombreRol}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Rol Tráfico</label>
                                {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}

                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectIdRoleT} value={selectedRoleT || ""}>
                                <option value={0}>-NO-</option>
                                {listRolesTrafico && listRolesTrafico?.map((rolesT) => (
                                    <option key={rolesT.id_roltrafico} value={rolesT.id_roltrafico}>
                                        {rolesT.descr_roltrafico}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="rowInfo">
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Empresas asoc.</label>
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                <select
                                    className="InputSelect"
                                    onChange={handleSelectIdEmpresaAsoc}
                                    value={selectedEmpresaAsoc || ""}
                                    style={{ flex: 1 }}
                                >
                                    <option value="" disabled>
                                        Selecciona una o varias empresas
                                    </option>
                                    {listEmpresas?.map((empresa) => (
                                        <option key={empresa.IdEmpresa} value={empresa.IdEmpresa}>
                                            {empresa.NombreEmpresa}
                                        </option>
                                    ))}
                                </select>

                                {selectedEmpresasAsocArray.length > 0 && (
                                    dropdownVisible ? (
                                        <i
                                            className="bx bx-chevron-up"
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                                color: "gray",
                                            }}
                                            onClick={() => setDropdownVisible(!dropdownVisible)}
                                        ></i>
                                    ) : (
                                        <i
                                            className="bx bx-chevron-down"
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                                color: "gray",
                                            }}
                                            onClick={() => setDropdownVisible(!dropdownVisible)}
                                        ></i>
                                    )
                                )}

                                {dropdownVisible && (
                                    <div style={{ ...styles.dropdownContainer, ...styles.customScroll, ...styles.webkitScroll }}>
                                        {selectedEmpresasAsocArray.map((empresa) => (
                                            <div
                                                key={empresa.id}
                                                style={styles.empresaRow}
                                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.empresaRowHover.backgroundColor)}
                                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = styles.empresaRow.backgroundColor)}
                                            >
                                                <span style={styles.empresaName}>{empresa.name}</span>
                                                <i
                                                    className="bx bx-x"
                                                    style={styles.removeIcon}
                                                    onClick={() => handleRemoveEmpresa(empresa.id)}
                                                ></i>
                                            </div>
                                        ))}
                                    </div>

                                )}
                            </div>
                        </MDBCol>
                    </MDBRow>


                </MDBCol >
                <MDBCol md={4}>
                    {/* <div style={{ border: '1px solid black', padding: '10px', marginTop: '20px' }}> */}
                    <fieldset>
                        <legend >Otros roles : </legend>

                        <div>
                            <SwitchButton checked={trafico} onChange={handleCheckboxChangeTrafico} type={' Tráfico'} />

                        </div>
                        <div>

                            <SwitchButton checked={inventarios} onChange={handleCheckboxChangeInventario} type={' Inventario'} />


                        </div>
                        <div>

                            <SwitchButton checked={geocercas} onChange={handleCheckboxChangeGeocerca} type={' Geocercas'} />


                        </div>
                        <div>

                            <SwitchButton checked={rutas} onChange={handleCheckboxChangeRuta} type={' Rutas'} />


                        </div>
                        <div>
                            <SwitchButton checked={comandos} onChange={handleCheckboxChangeComando} type={' Comandos'} />

                        </div>
                        <div>
                            <SwitchButton checked={ipFija} onChange={handleCheckboxChangeIpFija} type={' IP Fija'} />
                            {ipFija && (
                                <select
                                    // value={ipFijaOption}
                                    onChange={handleSelectIpFija}
                                    className="InputSelect"
                                >
                                    {listIps && listIps?.map((rolesT) => (
                                        <option key={rolesT.id_direccion} value={rolesT.id_direccion}>
                                            {rolesT.Nombre_direccion}
                                        </option>
                                    ))}
                                    <option value={0}>-NO-</option>
                                    <option value='AGREGAR'>-AGREGAR-</option>
                                </select>
                            )}
                        </div>
                    </fieldset>
                </MDBCol>

            </MDBRow >
            <div className="ButtonWrapper">
                <button
                    onClick={onSubmit}
                    className="Button blue"
                // onClick={(e) => { e.preventDefault(); handleSubmit() }}
                // data-bs-dismiss='modal'
                >Grabar</button>
            </div>

            <ModalBaseSm
                isOpen={modalOpenBaseSm}
                title={''}
                onClose={onCloseModalBaseSm}
                childComponent={<FormAddNewIpFija onClose={() => setModalOpenBaseSm(false)} selectedProyect={selectedProyect} refreshIPS={() => fetchIpsList(selectedProyect)} />}
            />

        </>
    )
}

export default FormAddNewUser
