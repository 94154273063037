import React from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "./Css/themes/base/all.css";
import "./Css/login.css";
import logo from "../logo.svg";
import "../App.css";
import TokenServiceRefresh from '../tokenServiceRefresh';
import TokenService from "../tokenServiceRefresh";
import axios from "../utils/axiosConfig";

var table;
var miStorage = window.localStorage;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    if (!localStorage.getItem("sw-empresa")) {
      localStorage.setItem("sw-empresa", true);
      localStorage.setItem("sw-ubicaciongps", true);
      localStorage.setItem("sw-dispositivo", true);
      localStorage.setItem("sw-ruta", true);
      localStorage.setItem("sw-ubicaciontrafico", true);
      localStorage.setItem("sw-tiempo", true);
      localStorage.setItem("sw-placa", true);
      localStorage.setItem("sw-bateria", true);
      localStorage.setItem("sw-estado", true);
      localStorage.setItem("sw-moving", true);
      localStorage.setItem("sw-locked", true);
      localStorage.setItem("sw-back", true);
      localStorage.setItem("sw-desvio", true);
      localStorage.setItem("sw-seguro", true);
      localStorage.setItem("sw-valitronics", true);

    }
  }

  componentDidMount() {
    $("#danger-alert").hide();
    $("#menu").hide();
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.login(); // Call the login function when Enter is pressed
    }
  };

  // METODO PARA LOGUEARSE EN EL SISTEMA
  login() {
    let data = {
      user: $("#user").val(),
      pass: $("#password").val(),
    };
    console.log("Data:", data); // Verificar los datos recibidos del formulario

    const json = JSON.stringify(data);
    axios
      .post(process.env.REACT_APP_SERVER + "/login", json, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((res) => {
        console.log("Login response:", res); // Verificar la respuesta del servidor
        if (res.data.success) {
          localStorage.setItem("tkn", res.data.token);
          localStorage.setItem("username", data.user);
          localStorage.setItem("server", res.data.entorno);
          localStorage.setItem("project", res.data.proyecto);
          localStorage.setItem("time_reload", res.data.timereload);
          // this.props.setUser({
          //   username: res.data.session.username,
          //   companyId: res.data.session.idempresa,
          //   server: res.data.entorno,
          // });
          localStorage.setItem("lastActivityTimeTokenCreate", new Date());
          const expirationTime = new Date().getTime() + (58 * 60 * 1000); // Calcular el tiempo de expiración
          localStorage.setItem("tokenExpiration", expirationTime); // Aquí se inicializa tokenExpiration

          // Iniciar el intervalo de actualización del token
          TokenServiceRefresh.startTokenRefreshIntervalOnLogin();

          window.location.href = "/principal";
        } else {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        }
      });
  }





  render() {
    return (
      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center mb-0">
                  <a href="/" className="app-brand-link">
                    <img
                      alt=""
                      className="style21"
                      src="./Cargotronics.png"
                      height="100"
                    />
                    {/* <span className="LabelLogo">INFOCARGA</span> */}
                  </a>
                </div>
                <h4 className="mb-5 text-center" style={{ fontSize: 20 }}>
                  Supply Chain Intelligence
                </h4>

                <div id="formAuthentication" className="mb-3">
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user"
                      name="email-username"
                      placeholder="Ingresa tu usuario"
                      autofocus
                    />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" for="password">
                        Password
                      </label>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        aria-describedby="password"
                        onKeyDown={this.handleKeyDown}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <button
                      id="btnLogin"
                      className="btn btn-outline-secondary d-grid"
                      type="submit"
                      onClick={this.login}
                    >
                      Ingresar
                    </button>
                  </div>
                </div>

                {/* <p className="text-center">
                  <span>Producto realizado por </span>
                  <span>Logiseguridad Ltda</span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> los datos ingresados son invalidos.
        </div>
      </div>
    );
  }
}

export default Login;
