import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import '../../Pages/Css/solicitudes.css';
import TooltipComponent from '../../componentes/radix/Tooltip';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from '../../utils/axiosConfig';

const FormReporteSolicitudes = ({ idSolicitud, onClose }) => {

    //VARIABLES
    const [fHora, setFHora] = useState(new Date());
    const [fHoraFormatted, setFHoraFormated] = useState(null);
    const [notas, setNotas] = useState(null);
    const [listReportes, setListReportes] = useState([]);
    const [listTiposEstados, setListTiposEstados] = useState([]);
    const [idReport, setIdReport] = useState(null);
    const [selectedTipyStatus, setSelectedTipyStatus] = useState(null);
    const userSolicitud = localStorage.getItem('username');

    //METHOD SORT
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    //FORMATEO FECHA Y HORA
    const handleDateTimeChangeHoraCita = (e) => {
        // Captura el valor del input
        const value = e.target.value;

        // Extrae la fecha y hora
        const [date, time] = value.split('T');

        // Agrega los segundos a la hora
        const timeWithSeconds = time.length === 5 ? `${time}:00` : time;

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${timeWithSeconds}`;

        // Actualiza los estados
        console.log('Datos de fecha antes de formatear: -->>', value);
        console.log('Datos fecha formateada: -->>', formattedDateTime);
        setFHoraFormated(formattedDateTime);
        setFHora(value);
    };

    const formattedSetTime = (time) => {
        // Divide la cadena en fecha y hora
        const [date, timePart] = time.split('T');

        // Asegúrate de que la parte de la hora no contenga la parte '.000Z'
        const cleanTimePart = timePart.split('.')[0];

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${cleanTimePart}`;

        console.log("Datos fecha formateado para actualizar ***", formattedDateTime);

        // Aquí puedes actualizar tu estado o realizar alguna acción con formattedDateTime
        setFHora(formattedDateTime);
        setFHoraFormated(formattedDateTime);
    };

    const handleSelectChangeTipoStatus = (event) => {
        const selectState = parseInt(event.target.value);
        setSelectedTipyStatus(selectState);
        console.log("Tipo contrato selescted: ", selectState);
    }

    //LISTAR SOLISICITUDES REPORTES
    const listarReporteSolicitudes = () => {
        let dataSolicitud = {
            id: idSolicitud
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getreportesSolicitudes", JSON.stringify(dataSolicitud), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO: ", res.data.data);
                    setListReportes(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR EESTADOS
    const listStatusReportesSeguimiento = () => {

        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaEstadosSolicitudes", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO tipos estados: ", res.data.data);
                    setListTiposEstados(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //CARGAR INFO
    const loadFillData = (array) => {
        // console.log("este es el array de la funcion loadFillData", array);
        return array.map((data) => {
            // Función para formatear la fecha y hora
            const formatDate = (dateTimeString) => {
                // Parsea el string ISO 8601 a objeto Date
                const parsedDate = parseISO(dateTimeString);

                // Formatea la fecha y hora según el formato deseado
                const formattedDate = format(parsedDate, "d MMMM yyyy h:mma", { locale: es });

                return formattedDate;
            };

            const formattedSetTime = (time) => {
                // Divide la cadena en fecha y hora
                const [date, timePart] = time.split('T');

                // Asegúrate de que la parte de la hora no contenga la parte '.000Z'
                const cleanTimePart = timePart.split('.')[0];

                // Combina la fecha y la hora formateada
                const formattedDateTime = `${date} ${cleanTimePart}`;

                console.log("Datos fecha formateado para actualizar ***", formattedDateTime);

                // Aquí puedes actualizar tu estado o realizar alguna acción con formattedDateTime
                return formattedDateTime;
            };


            const formatedTimeZone = formattedSetTime(data.hora);

            // Ejemplo de uso con la cadena formateada previamente
            const formattedDate = formatDate(formatedTimeZone); // Usar aquí tu cadena formateada anteriormente

            return (
                <React.Fragment >
                    {/* <tr onClick={() => this.toggleRow(data.ContractID)}> */}

                    <tr>

                        <td style={{ color: "#000", height: '10px' }}>{formattedDate}</td>

                        <td style={{ color: "#000" }}>{data.estado}</td>


                        <td style={{ color: "#000" }}>{data.nota}</td>


                        <td style={{ color: "#000" }}>{data.usuario}</td>


                        <td >
                            <a href='#' onClick={(e) => { e.preventDefault(); loadDataUpdate(data.id) }}><i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i></a>
                        </td>

                        <td >
                            <a href='#' onClick={(e) => { e.preventDefault(); deleteReporteSeguimiento(data.id) }}><i class='bx bx-trash' style={{ color: '#006699' }}></i></a>
                        </td>


                    </tr>

                </React.Fragment >
            )
        })
    }

    //CARGAR INFO PARA ACTUALIZAR
    const loadDataUpdate = (idReport) => {
        const filteredByIdReport = listReportes.filter((report) => report.id === idReport);
        const filteredByIdReportState = listTiposEstados.filter((reportstate) => reportstate.Descripcion === filteredByIdReport[0].estado);
        console.log("este es el array de la funcion loadDataUpdate cambio de estado : ", filteredByIdReportState);
        formattedSetTime(filteredByIdReport[0].hora);
        setNotas(filteredByIdReport[0].nota);
        setSelectedTipyStatus(filteredByIdReportState[0].IDEstados);
        setIdReport(filteredByIdReport[0].id);

    }

    //INSERTAR REPORTE DE SEGUIMIENTO
    const insertReporteSeguimiento = () => {
        let dataSolicitudReport = {
            FKLokEstadoID: selectedTipyStatus || listTiposEstados[0].IDEstados,
            Nota: notas,
            XTime: fHoraFormatted,
            XUser: userSolicitud,
            FKLokSolicitudID: idSolicitud
        }
        console.log("Data sreporte solcicitud antes de subirlo : ", dataSolicitudReport);
        if (!fHora || !notas) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debe llenar los campos obligatorios",
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        } else {
            axios
                .post(process.env.REACT_APP_SERVER + "/solicitudes/setinsertReporteSolicitud", JSON.stringify(dataSolicitudReport), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.success == false) {
                        window.location = "/";
                    } else {
                        console.log("Informacion REPORTE SEGU8IMINETO INSERTARRR *****: ", res.data.data);
                        listarReporteSolicitudes();
                        clearFields();
                    }
                }).catch((err) => console.log(err));
        }
    }

    //ACTUALIZAR REPORTE DE SEGUIMIENTO
    const updateReporteSeguimiento = () => {
        let dataSolicitudReportUPT = {
            FKLokEstadoID: selectedTipyStatus,
            Nota: notas,
            XTime: fHoraFormatted,
            XUser: userSolicitud,
            IdReport: idReport,
            FKLokSolicitudID: idSolicitud
        }
        if (!fHora || !notas) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debe llenar todos los campos.",
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        } else {
            axios
                .post(process.env.REACT_APP_SERVER + "/solicitudes/setupdateReporteSolicitud", JSON.stringify(dataSolicitudReportUPT), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.success == false) {
                        window.location = "/";
                    } else {
                        console.log("Informacion REPORTE SEGU8IMINETO ACTUALIZAR *****: ", res.data.data);
                        listarReporteSolicitudes();
                        clearFields();
                    }
                }).catch((err) => console.log(err));
        }
    }

    //ELIMINAR REPORTE DE SEGUIMIENTO
    const deleteReporteSeguimiento = (IdReport) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'custom-confirm-button', // or 'btn btn-info' if you use Bootstrap
                cancelButton: 'custom-cancel-button' // or 'btn btn-danger' if you use Bootstrap
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Está seguro?",
            text: "Esto no es reversible!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, anular",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {

                let dataReporteAnular = {
                    IdReport: IdReport,
                    XUser: userSolicitud,
                    FKLokSolicitudID: idSolicitud
                }
                axios
                    .post(process.env.REACT_APP_SERVER + "/solicitudes/setdeleteReporteSolicitud", JSON.stringify(dataReporteAnular), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                        },
                        withCredentials: true,
                    })
                    .then((res) => {
                        if (res.data.success == false) {
                            window.location = "/";
                        } else {
                            listarReporteSolicitudes();
                            swalWithBootstrapButtons.fire({
                                title: "Eliminado!",
                                text: "Su reporte de seguimiento ha sido anulada con éxito.",
                                icon: "success"
                            });

                        }
                    }).catch((err) => console.log(err));


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                console.log("no se anuilo la solicitud");
            }
        });


    }

    //LIMPIAR CAMPOS
    const clearFields = () => {
        cargarFechaHoraActual();
        setNotas("");
        setSelectedTipyStatus(listTiposEstados[0].IDEstados);
        setIdReport(null);
    }

    //CARGAR FECHA HORA ACTUAL APENAS SE CARGA EL COMPONENTE
    const cargarFechaHoraActual = () => {
        const ahora = new Date(); // Obtiene la fecha y hora actual en la zona horaria local
        const year = ahora.getFullYear();
        const month = ahora.getMonth() + 1;
        const day = ahora.getDate();
        const hours = ahora.getHours();
        const minutes = ahora.getMinutes();

        // Formatear la fecha y hora local
        const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        console.log("Hora actual local:", formattedDateTime);
        formattedSetTime(formattedDateTime);
        // Actualiza el estado con la fecha y hora local
    };




    const renderSortIcon = (column) => {

        if (sortBy === column) {
            if (sortDirection === "asc") {
                return <span>&uarr;</span>; // Icono de flecha hacia arriba
            } else {
                return <span>&darr;</span>; // Icono de flecha hacia abajo
            }
        }

        return null; // No mostrar ningún ícono si no está ordenado por esta columna
    }

    // const tiposEstados = [
    //     { id: 1, nombre: 'Pendiente' },
    //     { id: 2, nombre: 'En proceso' },
    //     { id: 3, nombre: 'Finalizado' },

    // ];

    useEffect(() => {
        listarReporteSolicitudes();
        listStatusReportesSeguimiento();
        cargarFechaHoraActual();
    }, []);



    // <i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i>
    return (
        <>
            <MDBRow style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', flexDirection: 'column' }}>
                {/* Número de solicitud */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className="InputTooltipWrapper reporteSeolicitud">
                            <label className="Label" htmlFor="solicitud">Nro. Solicitud</label>
                            {/* <TooltipComponent message={'Acá iría el número que identifica la solicitud en curso '} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={8} className='reporteSeolicitudInputt'>
                        <input className="Input" id="name" defaultValue={idSolicitud} disabled />
                    </MDBCol>
                </MDBCol>

                {/* Fecha y hora */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='InputTooltipWrapper reporteSeolicitud'>
                            <label className="Label" htmlFor="fechaHoraCita">Fecha/Hora</label>
                            {/* <TooltipComponent message={'Escoge una fecha y hora para tu solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={8} className='reporteSeolicitudInputt'>
                        <input className="Input" id="fechaHoraCita" type='datetime-local' value={fHora} onChange={handleDateTimeChangeHoraCita} />
                    </MDBCol>
                </MDBCol>

                {/* Estado de solicitud */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className="InputTooltipWrapper reporteSeolicitud">
                            <label className="Label" htmlFor="tipoContrato">Estado de solicitud</label>
                            {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}
                            {/* <TooltipComponent message={'Selecciona el estado de la solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        <select className="InputSelectReportSeguimihnetoSol" onChange={handleSelectChangeTipoStatus} value={selectedTipyStatus}>
                            {listTiposEstados.map((state) => (
                                <option key={state.IDEstados} value={state.IDEstados}>
                                    {state.Descripcion}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBCol>

                {/* Notas */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='contToltip reporteSeolicitud'>
                            <label className="Label" htmlFor="observacionesServicio">Notas</label>
                            {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        <textarea className="TextAreaReportSe" id='observacionesTextReporteSeguimiento' value={notas} onChange={(e) => setNotas(e.target.value)} />
                    </MDBCol>
                </MDBCol>
            </MDBRow>

            <div className="ButtonWrapper" style={{ marginBottom: '12px', display: 'flex', justifyContent: 'center', transform: 'translateX(100px)' }}>


                {idReport ? (
                    <button
                        className="Button blue"
                        onClick={(e) => { e.preventDefault(); updateReporteSeguimiento(); }}
                    >Actualizar</button>

                ) : (
                    <button
                        className="Button blue"
                        onClick={(e) => { e.preventDefault(); insertReporteSeguimiento(); }}
                    // data-bs-dismiss='modal'
                    >Grabar</button>

                )}



            </div>

            <MDBRow>
                <MDBCol md={12}>
                    <div className="card ContainerTableReportesSolicitudes">
                        <div
                            className="table-responsive text-nowrap"
                            style={{
                                height: `calc(100vh - 400px)`,
                                overflowY: "auto",
                                overflowX: "auto",
                                display: "flex",
                                // flexDirection: "column",
                            }}
                        >
                            <table className="table table-hover" id="tabla" style={{ overflowX: 'auto', width: 'auto' }}>
                                <thead className="FondoHeaderTabla2">
                                    <tr>

                                        <th
                                            style={{ color: "#ffffff", width: '100px' }}
                                        // onClick={() => sortData("IDSolicitudes")}
                                        >
                                            Fecha/Hora {renderSortIcon("IDSolicitudes")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: '150px', textAlign: 'center' }}
                                        // onClick={() => sortData("NombreEmpresa")}
                                        >
                                            Estado{" "}
                                            {renderSortIcon("NombreEmpresa")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: "400px", textAlign: 'center' }}
                                        // onClick={() => sortData("Ruta")}
                                        >
                                            Observaciones {renderSortIcon("Ruta")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: "100px", textAlign: 'center' }}
                                        // onClick={() => sortData("PlacaTruck")}
                                        >
                                            Usuario {renderSortIcon("PlacaTruck")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: "20px" }}
                                        // onClick={() => sortData("IconSeguro")}
                                        >
                                            {renderSortIcon("IconSeguro")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: "20px" }}
                                        // onClick={() => sortData("IconSeguro")}
                                        >
                                            {renderSortIcon("IconSeguro")}
                                        </th>


                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0" id="tabladata">
                                    {loadFillData(listReportes)}
                                    {/* el body */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default FormReporteSolicitudes
