import React from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Css/themes/base/all.css';
import logo from '../logo.svg';
import '../App.css';
import './Css/trafico.css';
import Menu from '../Modulos/Menu';
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import axios from '../utils/axiosConfig';
import { Button } from 'reactstrap';
import ModalBaseSm from '../components/modals/modal-base-sm';
import FormAddNewUser from '../components/formsSolicitudes/formAddNewUser';
import ModalBaseLg from '../components/modals/modal-base-lg';
import FormUpdateUser from '../components/formsSolicitudes/formUpdateUser';
import Swal from 'sweetalert2';
var table;

class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.ListarRoles = this.ListarRoles.bind(this);
    this.ListarUsuarios = this.ListarUsuarios.bind(this);
    this.state = {
      listRoles: [],
      listUsuarios: [],
      modalOpenBaseSm: false,
      modalOpenBaseSm2: false,
      bitOpen: false,
      bitInsert: false,
      bitEdit: false,
      bitDelete: false,
    };
    this.HandlePageByRole();
    this.ListarUsuarios();
    this.onCloseModalBaseSm = this.onCloseModalBaseSm.bind(this);
    this.onCloseModalBaseSm2 = this.onCloseModalBaseSm2.bind(this);
    this.listarUserAgain = this.listarUserAgain.bind(this);
  }

  componentDidUpdate() {
    $('#search').keyup();

  }
  componentDidMount() {
    $("#success-alert").hide();
    $("#danger-alert").hide();



    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#tabladata tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }

  HandlePageByRole = async () => {
    let data = {
      pagina: "2",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true, // this should be inside the options object
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState({
            bitOpen: res.data.data[0].bitOpen,
            bitInsert: res.data.data[0].bitInsert,
            bitEdit: res.data.data[0].bitEdit,
            bitDelete: res.data.data[0].bitDelete,
          }, () => {
            if (this.state.bitOpen) {
              console.warn("Tienes todos los permisos");

              return
            } else {
              Swal.fire({
                title: 'Información',
                text: 'No tiene los permisos necesarios para acceder a esta página',
                icon: 'info',
                timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
                showConfirmButton: false,
                allowOutsideClick: false, // Prevents clicking outside the modal to close it
                didOpen: () => {
                  Swal.showLoading(); // Shows the loading spinner
                },
                timer: 3000 // Time before redirecting (3 seconds)
              }).then(() => {
                // This will execute after the modal finishes showing
                window.location.href = "/principal"; // Redirect to another page
              });
            }

          })
        }

      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };


  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarRoles() {
    axios.get(process.env.REACT_APP_SERVER + '/operaciones/getroles', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listRoles: res.data.data });
        }
      }).catch((error) => { console.log(error) });
  }

  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarUsuarios() {
    axios.get(process.env.REACT_APP_SERVER + '/usuarios/getusuarios', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listUsuarios: res.data.data });
        }
      })
  }

  listarUserAgain() {
    console.log("Refrescando usuarios");
    this.ListarUsuarios();
  }

  // METODO PARA ABRIR MODAL DE EDICION DE USUARIO Y CARGAR LA INFO
  getInfoUserUpdate = async (data) => {
    let user = {
      username: data.idUser,
    }
    try {
      const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/getobtenerusuario', JSON.stringify(user), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('tkn')}`,
        },
        withCredentials: true,
      });
      if (res.data.success) {
        console.log("Informacion del usario para actulizar:", res.data.data);
        this.setState({ infoUsuario: res.data.data[0] }, () => {
          this.setState({ modalOpenBaseSm2: true });
        });
      } else {
        window.location = '/';
      }
    } catch (error) {
      console.error(error);
    }
  }


  OpenEdit(data) {
    console.log(data);
    if (this.state.bitEdit) {
      this.getInfoUserUpdate(data);
    } else {
      Swal.fire({
        title: 'Información',
        text: 'No tiene los permisos necesarios para acceder a esta opción',
        icon: 'info',
        timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
        showConfirmButton: false,
        allowOutsideClick: false, // Prevents clicking outside the modal to close it
        didOpen: () => {
          Swal.showLoading(); // Shows the loading spinner
        },
        timer: 3000 // Time before redirecting (3 seconds)
      }).then(() => {
        // This will execute after the modal finishes showing
        console.log("No tienes permisos")// Redirect to another page
      });
    }
  }

  openAdd() {
    console.log('openAdd');
    console.log(this.state.bitInsert);
    if (this.state.bitInsert) {
      this.setState({ modalOpenBaseSm: true });
    } else {
      Swal.fire({
        title: 'Información',
        text: 'No tiene los permisos necesarios para acceder a esta opción',
        icon: 'info',
        timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
        showConfirmButton: false,
        allowOutsideClick: false, // Prevents clicking outside the modal to close it
        didOpen: () => {
          Swal.showLoading(); // Shows the loading spinner
        },
        timer: 3000 // Time before redirecting (3 seconds)
      }).then(() => {
        // This will execute after the modal finishes showing
        console.log("No tienes permisos")// Redirect to another page
      });
    }
  }

  onCloseModalBaseSm() {
    this.setState({ modalOpenBaseSm: false });
  }

  onCloseModalBaseSm2() {
    this.setState({ modalOpenBaseSm2: false });
  }

  async DeleteUser(data) {
    console.log("informacion de usuario a borrar", data);
    if (this.state.bitDelete) {
      let username = {
        username: data.idUser
      };
      console.log(username);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button'
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: "Estas seguro?",
        text: "Esto no es reversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, anular",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await axios.post(
              process.env.REACT_APP_SERVER + '/usuarios/inhabilitarusuario',
              JSON.stringify(username),
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
              }
            );

            console.log(res);

            if (res.data && res.data.success) {
              this.listarUserAgain();
              Swal.fire({
                title: '¡Exito al eliminar usuario!',
                text: 'Informacion de usuario eliminada con éxito',
                icon: 'info',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
              });
            } else {
              window.location = '/';
            }
          } catch (error) {
            console.error("Error:", error);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("no se anulo la solicitud");
        }
      });
    } else {
      Swal.fire({
        title: 'Información',
        text: 'No tiene los permisos necesarios para acceder a esta opción',
        icon: 'info',
        timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
        showConfirmButton: false,
        allowOutsideClick: false, // Prevents clicking outside the modal to close it
        didOpen: () => {
          Swal.showLoading(); // Shows the loading spinner
        },
        timer: 3000 // Time before redirecting (3 seconds)
      }).then(() => {
        // This will execute after the modal finishes showing
        console.log("No tienes permisos")// Redirect to another page
      });
    }
  }





  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">


            <div className="layout-page">
              <Barra />
              <div className="content-wrapper">

                <div className="container-fluid flex-grow-1 container-p-y">
                  <MDBRow>
                    <MDBCol md='7'>
                      <h4 className="fw-bold mb-4">Usuarios</h4>
                    </MDBCol>
                    <MDBCol md='4'>
                      <div className="input-group col-md-12">
                        <span className="input-group-text" id="b1"><FaSearch /></span>
                        <input id="search" type="text" className="form-control" placeholder="Filtrar" aria-describedby="b1"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md='1'>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEnd"
                        aria-controls="offcanvasEnd"
                      >
                        <FaFilter />
                      </button>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: '20px' }}>
                    <div className="d-flex justify-content-start">
                      <Button size="medium" style={{ backgroundColor: '#006699', border: 'none' }}
                        onClick={() => this.openAdd()}
                      >
                        Agregar Usuario
                      </Button>
                    </div>
                  </MDBRow>

                  {this.state.bitOpen && (
                    <div className="card">
                      <div className="table-responsive text-nowrap">
                        <table className="table table-hover" id="tabla">
                          <thead className="FondoHeaderTabla2">
                            <tr>
                              <th style={{ color: '#ffffff' }}>USUARIO</th>
                              <th style={{ color: '#ffffff' }}>EMPRESA</th>
                              <th style={{ color: '#ffffff' }}>ROL</th>
                              <th style={{ color: '#ffffff' }}>ROL TRAFICO</th>
                              <th style={{ color: '#ffffff' }}>PROYECTO</th>
                              <th style={{ color: '#ffffff' }}>OPCIONES</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0" id="tabladata">
                            {this.loadFillData(this.state.listUsuarios)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

                <footer className="content-footer footer bg-footer-theme">
                  <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                      ©, Producto realizado por <a href="https://logiseguridad.com" target="_blank" className="footer-link fw-bolder"> Logiseguridad Ltda</a>
                    </div>

                  </div>
                </footer>

                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la información.
        </div>

        <ModalBaseLg
          isOpen={this.state.modalOpenBaseSm}
          title={'Crear un nuevo usuario'}
          onClose={this.onCloseModalBaseSm}
          childComponent={<FormAddNewUser onClose={this.onCloseModalBaseSm} refreshUsers={this.listarUserAgain} />}
        />
        <ModalBaseLg
          isOpen={this.state.modalOpenBaseSm2}
          title={'Actualizar usuario'}
          onClose={this.onCloseModalBaseSm2}
          childComponent={<FormUpdateUser onClose={this.onCloseModalBaseSm2} infoUsuario={this.state.infoUsuario} refreshUsers={this.listarUserAgain} />}
        />

      </>


    );
  }

  loadFillData(array) {


    return this.state.listUsuarios?.map((data) => {

      return (
        <tr key={data.idUser}>
          <td className="prueba"><strong>{data.idUser}</strong></td>
          <td>{data.NombreEmpresa}</td>
          <td>{data.NombreRol}</td>
          <td>{data.descr_roltrafico}</td>
          <td>{data.Descripcion}</td>
          <td>
            <div className="dropdown position-static">
              <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                <i className="bx bx-dots-vertical position-static"></i>
              </button>
              <div className="dropdown-menu">
                <span onClick={() => this.OpenEdit(data)} className="dropdown-item">
                  <i className="bx bx-edit me-1"></i> Editar
                </span>
                <span onClick={() => this.DeleteUser(data)} className="dropdown-item">
                  <i className="bx bx-file me-1"></i> Eliminar
                </span>
                <span onClick={() => this.OpenPermisos(data)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                  <i className="bx bx-file me-1"></i> Ver Permisos
                </span>
              </div>
            </div>
          </td>

        </tr>
      )
    });
    $('.dropdown-toggle').dropdown();

  }
}

export default Usuarios;
