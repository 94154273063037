import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const LoadFillFilters = ({ loadFilter, onClose, cleanFilters }) => {
    const [filters, setFilters] = useState([]);

    const getFilters = async () => {
        try {
            const res = await axios.get(
                process.env.REACT_APP_SERVER + "/contratos/getfiltros",
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );
            if (res.data.success === false) {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo obtener la información, error de conexión',
                    icon: 'error',
                    showConfirmButton: true,
                });
            } else {
                console.log("estos son los filtros para el usuario", res.data.data);
                setFilters(res.data.data); // Guardar los filtros en el estado

            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: `Error, ${error}`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    };

    const handleSelectedFilter = async (filter) => {
        try {
            const filterSelected = filter?.FiltroJson;
            const filterIdSelected = filter?.IdFiltro;
            console.log("filtersSelected", filterSelected);
            localStorage.setItem('arrayFilters', filterSelected);
            localStorage.setItem('idFilterSelected', filterIdSelected);
            localStorage.setItem('nameFilters', filter?.DescripcionFiltro);
            localStorage.setItem('orderByCriteria', filter?.FiltroJson?.orderByCriteria);
            localStorage.setItem("statusFiltersNew", filterSelected);
            window.location.reload();
            // loadFilter(filterSelected);
            // onClose();
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: `Error, ${error}`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    }

    const handleRemoveFilter = async (filter) => {
        Swal.fire({
            title: '¿Está seguro?',
            text: `Está a punto de eliminar el filtro "${filter.DescripcionFiltro}". Esta acción no se puede deshacer.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#006699',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let idFilter = { IdFiltro: filter.IdFiltro };
                console.log("idFilter", idFilter);
                try {
                    const res = await axios.post(
                        process.env.REACT_APP_SERVER + "/contratos/deletefiltro",
                        JSON.stringify(idFilter),
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                            },
                            withCredentials: true,
                        }
                    );
                    if (res.data.success === false) {
                        Swal.fire({
                            title: 'Error',
                            text: 'No se pudo eliminar el filtro, error de conexión',
                            icon: 'error',
                            showConfirmButton: true,
                        });
                    } else {
                        Swal.fire({
                            title: 'Eliminado',
                            text: 'El filtro ha sido eliminado exitosamente.',
                            icon: 'success',
                            showConfirmButton: true,
                        });
                        await getFilters(); // Actualizar la lista de filtros después de eliminar
                        cleanFilters();
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire({
                        title: 'Error',
                        text: `Error: ${error.message}`,
                        icon: 'error',
                        showConfirmButton: true,
                    });
                }
            }
        });
    };


    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        if (filters) {
            console.log("filtros cargados", filters);
        }
    }, [filters])

    const styles = {
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '20px',
            padding: '20px',
            maxHeight: '400px',
            overflowY: 'auto',
        },
        card: {
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            transition: 'transform 0.2s',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'flex',
            justifyContent: 'space-between',

        },
        cardHover: {
            transform: 'scale(1.05)',
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
            color: '#333',
        },
        description: {
            fontSize: '14px',
            color: '#666',
        },
        trashIcon: {
            color: 'red',
            cursor: 'pointer',
        }
    };

    return (
        <div style={styles.container}>
            {filters.length > 0 ? (
                filters.map((filter, index) => (
                    <div
                        key={index}
                        style={styles.card}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        onClick={() => handleSelectedFilter(filter)}
                    >
                        <h3 style={styles.title}>{filter.DescripcionFiltro || 'Sin descripción'}</h3>
                        <i
                            className="bx bxs-trash bx-sm bx-tada-hover"
                            style={styles.trashIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFilter(filter);
                            }}
                        ></i>

                    </div>
                ))
            ) : (
                <p>No hay filtros disponibles</p>
            )}
        </div>
    );
};

export default LoadFillFilters;
