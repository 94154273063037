import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'
import Swal from 'sweetalert2';
import axios from '../../utils/axiosConfig';

const style = {
    containerButton: {
        display: 'flex',
        justifyContent: 'center',
        gap: 15,
        flexDirection: 'row',
        marginTop: 24
    },
    buttonCancelar: {
        backgroundColor: 'red',
        border: 'none'
    },
    buttonEliminar: {
        backgroundColor: '#006699',
        border: 'none'
    },
}

const CausalAnularSolicitud = ({ idSolicitud, onClose }) => {

    const [causal, setCausal] = useState(null);

    //ANULAR SOLICITUDES
    const showDialogAlertForAnular = () => {
        if (!causal) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debe indicar la causal de eliminación",
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        } else {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'custom-confirm-button', // or 'btn btn-info' if you use Bootstrap
                    cancelButton: 'custom-cancel-button' // or 'btn btn-danger' if you use Bootstrap
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "Está seguro?",
                text: "Esto no es reversible!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, anular",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {


                    let dataSolicitudAnular = {
                        idsolicitud: idSolicitud,
                        causal: causal
                    }
                    axios
                        .post(process.env.REACT_APP_SERVER + "/solicitudes/deletesolicitud", JSON.stringify(dataSolicitudAnular), {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                            },
                            withCredentials: true,
                        })
                        .then((res) => {
                            if (res.data.success == false) {
                                window.location = "/";
                            } else {
                                onClose();
                                swalWithBootstrapButtons.fire({
                                    title: "Eliminado!",
                                    text: "Tu solicitud a sido anulada con éxito.",
                                    icon: "success"
                                });

                            }
                        }).catch((err) => console.log(err));


                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    console.log("no se anuilo la solicitud");
                }
            });

        }
    }
    return (
        <div>
            <h3>Causal de eliminación de la solicitud {idSolicitud}</h3>
            <Input
                type="textarea"
                placeholder="Write something (data should remain in modal if unmountOnClose is set to false)"
                rows={5}
                value={causal}
                onChange={(e) => setCausal(e.target.value)}
            />
            <div style={style.containerButton}>
                <Button style={style.buttonEliminar} onClick={showDialogAlertForAnular}>Eliminar</Button>
                <Button style={style.buttonCancelar}>Cancelar</Button>
            </div>
        </div>
    )
}

export default CausalAnularSolicitud
