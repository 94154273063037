import React from "react";
import SettingsModal from "../components/modals/settings";
import axios from "../utils/axiosConfig";
import ModalBaseSm from './../components/modals/modal-base-sm';
import FormUpdatePassword from "../components/formsSolicitudes/formUpdatePassword";
import "../Pages/Css/navbarPrincipal.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";



const server = localStorage.getItem("server");
const userName = localStorage.getItem("username");
var miStorage = window.localStorage

class BarraSolicitud extends React.Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.state = {
            openModal: false,
            openModalChangePassword: false,
            showNotificationDropdown: false,
            solicitudes: [],
            hasNewNotification: false,
            confirmacionIndex: null,
            animatingIndex: null,
            userSolicitud: localStorage.getItem('username'),
            bitOpen: false,
            bitInsert: false,
            bitEdit: false,
            bitDelete: false,
            audioReady: false,
        };
        this.HandlePageByRole();
        this.dropdownRef = React.createRef();
        this.bellIconRef = React.createRef();
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModalChangePassword = this.openModalChangePassword.bind(this);
        this.closeModalChangePassword = this.closeModalChangePassword.bind(this);
        this.toggleNotificationDropdown = this.toggleNotificationDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.socket = null;
        this.notificationSound = new Audio('/audio/notificationpush.mp3');

    }

    handleUserInteraction = () => {
        this.setState({ audioReady: true });
        // Opcional: eliminar el listener si solo se necesita una vez
        document.body.removeEventListener('click', this.handleUserInteraction);
    };

    playNotificationSound = () => {
        if (this.state.audioReady) {
            this.notificationSound.play().catch((error) => {
                console.error('Error al intentar reproducir el sonido:', error);
            });
        }
    };

    componentDidMount() {
        console.log("Este es el prop para contador blue", this.props.count);
        console.log("Este es el prop para solciitudes", this.props.filteredSolicitudes);
        document.addEventListener('mousedown', this.handleClickOutside);
        let filterSolicitudes = this.props.filteredSolicitudes.filter(data => data.estado === 'Solicitud Cliente por Confirmar');
        const ordenadoPorIDDescendente = filterSolicitudes.sort((a, b) => {
            const numA = parseInt(a.IDSolicitudes.split('-')[1]);
            const numB = parseInt(b.IDSolicitudes.split('-')[1]);
            return numB - numA;
        });
        this.setState({ solicitudes: ordenadoPorIDDescendente }, () => (console.log("solicitudes Filtrdas", this.state.solicitudes)));
        // Conectar al WebSocket
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {

                    document.body.addEventListener('click', this.handleUserInteraction);
                    // Conectar al WebSocket
                    const token = localStorage.getItem('tkn');
                    this.socket = new WebSocket(`wss://${process.env.REACT_APP_SERVER_WEBSOCKET}/websocket/`, token);

                    // Manejar mensajes recibidos del servidor 
                    this.socket.onmessage = (event) => {
                        const data = JSON.parse(event.data);
                        console.log("Data del servidor:", data);
                        console.log('Mensaje del servidor:', data.message, "event:", data.event);

                        // Actualizar el estado con el nuevo mensaje
                        if (data.event === true) {
                            this.props.onUpdateSolicitudes(data.data);
                            console.log("Data del servidor:", data.data);
                            // const reversedData = data.data.reverse();
                            // const firstSolicitud = reversedData[0];
                            // console.log("Ultima solicitud", firstSolicitud);
                            const ordenadoPorIDDescendente = data.data.sort((a, b) => {
                                const numA = parseInt(a.IDSolicitudes.split('-')[1]);
                                const numB = parseInt(b.IDSolicitudes.split('-')[1]);
                                return numB - numA;
                            });
                            console.log("Ordenado por ID descendente", ordenadoPorIDDescendente);
                            this.showNotification(ordenadoPorIDDescendente[0]); // Muestra la notificación
                            console.warn("Solicitudes actualizadas");
                            this.setState({ hasNewNotification: true });
                        }
                    };

                    // Manejar errores de conexión
                    this.socket.onerror = (error) => {
                        console.error('Error en la conexión WebSocket:', error);
                    };

                    // Manejar el cierre de la conexión
                    this.socket.onclose = (event) => {
                        console.log('Conexión cerrada', event.reason);
                    };
                }
            });
        }
    }


    showNotification = (message) => {
        if (Notification.permission === "granted") {
            const notification = new Notification("Tiene una nueva solicitud por atender", {
                body: `ID Solicitud: ${message.IDSolicitudes}\nEmpresa: ${message.NombreEmpresa}\nRuta: ${message.Ruta}`,
                icon: "./Cargotronics_favicon.png",
            });

            // Reproducir el sonido de notificación
            this.notificationSound.play().catch((error) => {
                console.error('Error al intentar reproducir el sonido:', error);
            });

            notification.onclick = () => {
                console.log('Notificación clicada');

                window.open('/solicitudes', '_blank');
                // Puedes redirigir al usuario a otra parte de la aplicación si lo deseas
            };
        } else {
            console.error("Las notificaciones no están habilitadas");
        }
    };


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside); // Remover el event listener
        if (this.socket) {
            this.socket.close(); // Limpiar la conexión al desmontar el componente
        }
    } // Limpia el evento clic

    componentDidUpdate(prevProps) {
        console.log("filteredSolicitudes (prev):", prevProps.filteredSolicitudes.length);
        console.log("filteredSolicitudes (current):", this.props.filteredSolicitudes.length);
        if (prevProps.filteredSolicitudes !== this.props.filteredSolicitudes) {
            let filterSolicitudes = this.props.filteredSolicitudes.filter(data => data.estado === 'Solicitud Cliente por Confirmar');
            const ordenadoPorIDDescendente = filterSolicitudes.sort((a, b) => {
                const numA = parseInt(a.IDSolicitudes.split('-')[1]);
                const numB = parseInt(b.IDSolicitudes.split('-')[1]);
                return numB - numA;
            });
            this.setState({ solicitudes: ordenadoPorIDDescendente }, () => { console.log("solicitudes Filtrdas", this.state.solicitudes); console.log("solicitudes Filtrdas :length", this.state.solicitudes.length) });
        }
    }


    handleUserInteraction = () => {
        this.setState({ audioReady: true });
    };


    toggleNotificationDropdown() {
        this.setState((prevState) => ({
            showNotificationDropdown: !prevState.showNotificationDropdown,
            hasNewNotification: false
        }));
    }

    openModal() {
        this.setState({ openModal: true });
    }

    openModalChangePassword() {
        this.setState({ openModalChangePassword: true });
    }

    closeModalChangePassword() {
        this.setState({ openModalChangePassword: false });
    }

    closeModal() {
        this.setState({ openModal: false });
    }

    handleClickOutside(event) {
        // Verifica si las referencias existen antes de usarlas
        if (
            this.dropdownRef.current && // Verifica si el dropdownRef está definido
            this.bellIconRef.current && // Verifica si el bellIconRef está definido
            !this.dropdownRef.current.contains(event.target) &&
            !this.bellIconRef.current.contains(event.target)
        ) {
            this.setState({ showNotificationDropdown: false });
        }
    }

    HandlePageByRole = async () => {
        let data = {
            pagina: "20",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(data),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            if (res.data.success === false) {
                window.location.href = "/";
            } else {
                console.log("BIt permisos: ", res.data.data);
                if (res.data.data.length > 0) {
                    this.setState({
                        bitOpen: res.data.data[0].bitOpen,
                        bitInsert: res.data.data[0].bitInsert,
                        bitEdit: res.data.data[0].bitEdit,
                        bitDelete: res.data.data[0].bitDelete,
                    })

                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                    } else {
                        console.log("No tienes permisos para ver las notificaciones");
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }
    };

    getCurrentDateTime = () => {
        const now = new Date();

        // Formato YYYY-MM-DD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Meses son base 0
        const day = String(now.getDate()).padStart(2, '0');

        // Formato HH:mm:ss
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        // Combina todo en el formato deseado
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };


    handleAceptarClick = (index) => {
        if (this.state.bitInsert) {
            this.setState({ confirmacionIndex: index, animatingIndex: index }); // Muestra el mensaje de confirmación
        } else {
            Swal.fire({
                title: 'Información',
                text: 'No tiene los permisos necesarios para aceptar la solicitud',
                icon: 'info',
                showConfirmButton: false,
                timer: 3000,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            })
        }
    };

    handleConfirmarClick = (index) => {
        const solicitud = this.state.solicitudes[index];
        this.handleSolicitudClick(solicitud); // Llama a la función de manejo de la solicitud
        this.setState({ confirmacionIndex: null, animatingIndex: null }); // Oculta el mensaje de confirmación
    };

    handleCancelarClick = () => {
        this.setState({ confirmacionIndex: null, animatingIndex: null }); // Oculta el mensaje de confirmación
    };

    //MEtodo para cmabiar estado de solicitud "ACEPTADA"
    handleSolicitudClick = (Solicitud) => {
        let fechaHora = this.getCurrentDateTime();
        let dataSolicitudReport = {
            FKLokEstadoID: 14,
            Nota: "",
            XTime: fechaHora,
            XUser: this.state.userSolicitud,
            FKLokSolicitudID: Solicitud.IDSolicitudes
        };
        console.log("Data Solicitud Report desde notificaciones", dataSolicitudReport); // Asegúrate de que `dataSolicitudReport` esté definido antes de esta línea.

        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/setinsertReporteSolicitud", JSON.stringify(dataSolicitudReport), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success === false) {
                    window.location = "/";
                } else {
                    console.log("Información REPORTE SEGUIMIENTO INSERTARRR *****: ", res.data.data);
                    this.props.ListarContratos();
                    // this.setState({ showNotificationDropdown: false });
                }
            }).catch((err) => console.log(err));

    };



    // METODO PARA DESLOGUEARSE DEL SISTEMA
    logout() {
        axios
            .get(process.env.REACT_APP_SERVER + "/logout", {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((res) => {
                localStorage.removeItem("tkn");
                localStorage.removeItem("username");
                localStorage.removeItem("companyId");
                localStorage.removeItem("server");
                localStorage.removeItem('isRefreshIntervalActive');
                localStorage.removeItem("username");
                localStorage.removeItem("companyId");
                localStorage.removeItem("server");
                localStorage.removeItem("project");
                localStorage.removeItem('idFilterSelected');
                localStorage.removeItem('arrayFilters');
                window.location = "/";
            });
    }

    render() {
        return (
            <>
                <nav
                    className="layout-navbar container-fluid navbar navbar-expand-xl  align-items-center layout-navbar container-fluid navbar navbar-expand-xl align-items-center custom-navbar-admin"
                    id="layout-navbar"

                >
                    <a href="/principal" className="app-brand-link">
                        <img alt="" className="style22" src="./Cargotronics.png" height="80" />
                        {/* <span className="LabelLogo">CARGOTRONICS</span> */}
                    </a>
                    <div
                        className="navbar-nav-right d-flex align-items-center"
                        id="navbar-collapse"
                    >
                        {/* <div className="LabelBarra">
                            <label style={{color:'black'}}>
                                Supply Chain Intelligence
                            </label>
                        </div> */}
                        <ul className="navbar-nav flex-row align-items-center ms-auto" >
                            {this.state.bitOpen && (
                                <div style={{ position: 'relative', display: 'inline-block', right: '15px' }}>
                                    <i className={`bx bxs-bell-ring bx-md notification-icon ${this.state.hasNewNotification ? 'bx-tada-hover-solicitudes' : ''}`}
                                        ref={this.bellIconRef}
                                        onClick={this.toggleNotificationDropdown} // Manejador de clic
                                        style={{ cursor: 'pointer' }}
                                    ></i>
                                    <span
                                        className="notificationsCount"
                                        style={{
                                            position: 'absolute',
                                            top: '-2px',
                                            right: '20px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            borderRadius: '50%',
                                            padding: '2px 6px',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {this.state.solicitudes.length}
                                    </span>
                                    {this.state.showNotificationDropdown && (
                                        <ul
                                            className="dropdown-menu show"
                                            id="notificationDropdown"
                                            ref={this.dropdownRef}
                                            style={{
                                                position: 'absolute',
                                                top: '100%',
                                                right: 0,
                                                width: '500px',
                                                height: 'calc(100vh - 200px)',
                                                overflowY: 'auto',
                                                zIndex: 1000,
                                                padding: '10px',
                                                backgroundColor: '#fff',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            {/* Título */}
                                            <h5 style={{ textAlign: 'center', padding: '5px', marginBottom: '10px', fontWeight: 'bold' }}>
                                                Solicitudes sin atender
                                            </h5>
                                            <hr style={{ margin: '0 10px 10px' }} />

                                            {/* Si hay solicitudes */}
                                            {this.state.solicitudes.length > 0 ? (
                                                this.state.solicitudes.map((solicitud, index) => (
                                                    <li className="dropdown-item cursor-pointer flex items-start justify-between" key={index} style={{ padding: '8px 10px', display: 'flex' }}>
                                                        <span className="notification-dot"></span>
                                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                            {this.state.confirmacionIndex === index ? (


                                                                <div style={{ height: '98px', display: 'flex', flexDirection: 'flex', justifyContent: 'start', alignItems: 'center', padding: '10px', fontSize: '16px' }}>
                                                                    <span>¿Confirmar aceptación de la solicitud?</span>

                                                                    <button
                                                                        style={{ marginLeft: '10px', color: 'green' }}
                                                                        onClick={() => this.handleConfirmarClick(index)}>
                                                                        Confirmar
                                                                    </button>
                                                                    <button
                                                                        style={{ marginLeft: '10px', color: 'red' }}
                                                                        onClick={this.handleCancelarClick}>
                                                                        Cancelar
                                                                    </button>

                                                                </div>


                                                            ) : (
                                                                <>
                                                                    <strong>{solicitud.IDSolicitudes}</strong>
                                                                    <span style={{ whiteSpace: "nowrap", overflow: 'hidden', textOverflow: 'ellipsis' }}>{solicitud.NombreEmpresa}</span>
                                                                    <span>{solicitud.Ruta}</span>
                                                                    <span>{solicitud.nota}</span>
                                                                </>
                                                            )}
                                                        </div>

                                                        {this.state.confirmacionIndex !== index && (
                                                            <button className="btn-aceptar"
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    padding: '4px 10px',
                                                                    backgroundColor: 'transparent',
                                                                    color: '#006699',
                                                                    border: 'none',
                                                                    cursor: 'pointer',
                                                                    borderLeft: '1px solid black'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation(); // Evita que el clic en el botón active el evento del li
                                                                    this.handleAceptarClick(index);
                                                                }}>
                                                                Aceptar
                                                            </button>
                                                        )}
                                                    </li>
                                                ))
                                            ) : (
                                                <p style={{ textAlign: 'center', padding: '10px', color: '#555' }}>
                                                    No tienes solicitudes por confirmar
                                                </p>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <span className="fw-semibold d-none d-lg-block" style={{ marginRight: 5 }}>
                                {userName ? userName : "John Doe"}
                            </span>
                            <li className="nav-item navbar-dropdown dropdown-user dropdown">
                                <a
                                    className="nav-link dropdown-toggle hide-arrow"
                                    data-bs-toggle="dropdown"
                                >
                                    <div className="avatar avatar-online" id="avtarIcon">
                                        <img
                                            src="../assets/img/avatars/1.png"
                                            alt=""
                                            className="w-px-40 h-auto rounded-circle"
                                        />
                                    </div>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="../assets/img/avatars/1.png"
                                                            alt=""
                                                            className="w-px-40 h-auto rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-semibold d-block">
                                                        {userName ? userName : "John Doe"}
                                                    </span>
                                                    <small className="text-muted">Admin</small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider"></div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/myprofile">
                                            <i className="bx bx-user me-2"></i>
                                            <span className="align-middle">My Profile</span>
                                        </a>
                                    </li>
                                    <li>
                                        <span
                                            onClick={this.openModal}
                                            data-bs-target="#ModalSettings"
                                            data-bs-toggle="modal"
                                        >
                                            <a className="dropdown-item" href="#">
                                                <i className="bx bx-cog me-2"></i>
                                                <span
                                                    className="align-middle"
                                                    data-bs-target="#ModalSettings"
                                                >
                                                    Settings
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={this.openModalChangePassword}
                                        >
                                            <a className="dropdown-item" href="#">
                                                <i className="bx bx-lock me-2"></i>
                                                <span
                                                    className="align-middle"
                                                >
                                                    Cambiar contraseña
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <span className="d-flex align-items-center align-middle">
                                                <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                                                <span className="flex-grow-1 align-middle">
                                                    Billing
                                                </span>
                                                <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
                                                    4
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider"></div>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => this.logout()}>
                                            <i className="bx bx-power-off me-2"></i>
                                            <span className="align-middle">Log Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div
                    className="modal fade"
                    id="settingsModal"
                    data-bs-backdrop="static"
                    tabIndex="-1"
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p>Set</p>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <SettingsModal
                                    isOpen={this.state.openModal}
                                    onClose={this.closeModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="ModalSettings"
                    data-bs-backdrop="static"
                    tabIndex="-1"
                >
                    <div className="modal-dialog modal-lg">
                        <form className="modal-content" id="ModalSettings">
                            <div className="modal-header">
                                <h5 className="modal-title" id="backDropModalTitle">
                                    Base de datos: {server === "Prod" ? "Producción" : "Pruebas"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.closeModal}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <SettingsModal
                                        isOpen={this.state.openModal}
                                        onClose={this.closeModal}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* //ModalBaseSm */}
                < ModalBaseSm
                    isOpen={this.state.openModalChangePassword}
                    title={'Cambiar contraseña'}
                    onClose={this.closeModalChangePassword}
                    childComponent={< FormUpdatePassword onClose={this.closeModalChangePassword} />}
                />
            </>
        );
    }
}

export default BarraSolicitud;
