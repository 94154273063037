import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from '../../utils/axiosConfig';

const FormSaveFilters = ({ onClose, filterNameChange }) => {
    const [nameFiltersToSave, setNameFiltersToSave] = useState('');
    const [canSaveAsNew, setCanSaveAsNew] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [filters, setFilters] = useState([]);
    const [idFilterSelected, setIdFilterSelected] = useState(localStorage.getItem('idFilterSelected') || null);

    const arrayFilters = localStorage.getItem('arrayFilters');
    const userName = localStorage.getItem('username');
    const filterName = localStorage.getItem('nameFilters');

    // Obtener filtros desde el backend
    const getFilters = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER}/contratos/getfiltros`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                    },
                }
            );
            if (res.data.success) {
                setFilters(res.data.data);

                // Actualizar el ID del filtro si coincide con el nombre
                const selectedFilter = res.data.data.find(
                    (filter) =>
                        filter.DescripcionFiltro.trim().toLowerCase() ===
                        nameFiltersToSave.trim().toLowerCase()
                );
                setIdFilterSelected(selectedFilter?.IdFiltro || idFilterSelected);
                localStorage.setItem('idFilterSelected', selectedFilter?.IdFiltro || idFilterSelected);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo obtener la información, error de conexión',
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            console.error('Error al obtener filtros:', error);
            Swal.fire({
                title: 'Error',
                text: `Error: ${error.message}`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    };

    // Función para enviar los datos al backend
    const onSubmit = async (data, action) => {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_SERVER}/contratos/insertfiltro`,
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                    },
                }
            );

            if (!res.data.success) {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo guardar la información, error de conexión',
                    icon: 'error',
                    showConfirmButton: true,
                });
            } else {
                Swal.fire({
                    title: 'Éxito!',
                    text: `Filtro ${action} exitosamente!`,
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonColor: '#006699',
                });

                // Actualizar LocalStorage
                localStorage.setItem('statusFilters', 'unsaved');
                localStorage.setItem('nameFilters', data.DescripcionFiltro);

                // Obtener filtros actualizados
                await getFilters();

                onClose();
            }
        } catch (error) {
            console.error('Error al guardar el filtro:', error);
            Swal.fire({
                title: 'Error',
                text: `Ocurrió un error al intentar ${action} el filtro.`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    };

    // Manejo de las acciones
    const handleAction = (action) => {
        if (!nameFiltersToSave.trim()) {
            Swal.fire({
                title: 'Error',
                text: 'El nombre del filtro no puede estar vacío.',
                icon: 'warning',
                showConfirmButton: true,
            });
            return;
        }

        const data = {
            IdFiltro: action === 'guardado como nuevo' ? -1 : idFilterSelected,
            DescripcionFiltro: nameFiltersToSave,
            FiltroJson: arrayFilters,
            FkIdUser: userName,
        };

        const actionText = action === 'actualizado' ? 'actualizado' : 'guardado como nuevo';
        onSubmit(data, actionText);
    };

    // Actualizar estado de botones
    useEffect(() => {
        const isSameName = nameFiltersToSave.trim() === filterName?.trim();
        const isNewName = nameFiltersToSave.trim() !== filterName?.trim();

        setCanUpdate(!!idFilterSelected); // Habilitar "Actualizar" solo si existe el ID
        setCanSaveAsNew(isNewName);
    }, [nameFiltersToSave, idFilterSelected, filterName]);

    // Carga inicial del nombre del filtro y los filtros
    useEffect(() => {
        if (filterName) {
            setNameFiltersToSave(filterName);
            filterNameChange?.(filterName);
        }
        getFilters();
    }, [filterName, filterNameChange]);

    return (
        <>
            <Container className="d-flex justify-content-center px-0">
                <Row className="w-100">
                    <Col className="d-flex flex-column align-items-center" style={{ paddingRight: '40px' }}>
                        <FormGroup className="w-100">
                            <Label for="input1">Nombre para grupo de filtros</Label>
                            <Input
                                type="text"
                                id="input1"
                                className="w-100"
                                placeholder="FILTROS IMPORTANTES ..."
                                style={{ textTransform: 'uppercase' }}
                                value={nameFiltersToSave}
                                maxLength={50}
                                onChange={(e) => setNameFiltersToSave(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

            </Container>
            <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                    <Button
                        className="Button blue"
                        onClick={() => handleAction('actualizado')}
                        disabled={!idFilterSelected}
                    >
                        Actualizar
                    </Button>
                </Col>
                <Col className="d-flex justify-content-center">
                    <Button
                        className="Button blue"
                        onClick={() => handleAction('guardado como nuevo')}
                        disabled={!canSaveAsNew}
                    >
                        Guardar como
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default FormSaveFilters;
