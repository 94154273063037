import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useState } from 'react'
import { Button } from 'reactstrap';
import axios from '../../utils/axiosConfig';
import Swal from 'sweetalert2';

const FormAddNewIpFija = ({ onClose, selectedProyect, refreshIPS}) => {
    const [nombre, setNombre] = useState('');
    const [dir, setDir] = useState('');

    const addNewIps = async (dataIps) => {
        try {
            const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/crearip', dataIps, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });
            if (res.data.success) {
                onClose();
                refreshIPS();
                Swal.fire({
                    title: '¡Exito al registrar nueva IPS!',
                    text: 'Tus cambios se guardaron exitosamente.',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar',
                });
            } else {
                window.location = '/';
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleIpChange = (e) => {
        let value = e.target.value;

        // Remover caracteres no numéricos o puntos
        value = value.replace(/[^\d.]/g, '');

        // Evitar más de 3 dígitos por octeto y colocar puntos automáticamente
        if (value.length <= 15) {
            let formattedValue = value.split('.').map(octet => octet.substring(0, 3)).join('.');
            formattedValue = formattedValue.replace(/(\d{3})(\d)/g, '$1.$2'); // Coloca puntos en la posición correcta
            setDir(formattedValue);
        }
    };

    const onSubmit = () => {
        
        const dataIps = {
            nombre: nombre,
            ip: dir,
            proyecto: selectedProyect
        }
        console.log(dataIps);
        addNewIps(dataIps);
    }

    return (
        <>
            <MDBRow>
                <MDBCol md={6}>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Nombre</label>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" id="name" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Dir</label>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" id="dir" value={dir} onChange={handleIpChange} />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow >
            <MDBRow style={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
                <Button
                    onClick={onSubmit}
                    className="Button blue"
                    style={{ width: '30%' }}  // El botón ocupará el 100% del contenedor
                >
                    Grabar
                </Button>
            </MDBRow>
        </>
    )
}

export default FormAddNewIpFija
