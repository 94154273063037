import React from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Css/themes/base/all.css';
import logo from '../logo.svg';
import '../App.css';
import './Css/trafico.css';
import Menu from '../Modulos/Menu';
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import axios from '../utils/axiosConfig';
import { formattedGlobalDate } from '../utils/formattedDates';
import PreloaderHistorico from '../components/preloaders/preloader-historico';
import Swal from 'sweetalert2';
var table;
var miStorage = window.localStorage;
class ControlDevices extends React.Component {
  constructor(props) {
    super(props);
    this.ListarInfo = this.ListarInfo.bind(this);
    this.offcanvasRef = React.createRef();
    this.state = {
      listInfo: [],
      orden1: "Activos",
      orden2: "0",
      proyectos: [],
      selectedProyecto: null,
      showPreloader: false,
      bitOpen: false,
      bitInsert: false,
      bitEdit: false,
      bitDelete: false,
    };
    this.HandlePageByRole();
    this.listarProyectos();

  }

  componentDidUpdate() {
    $('#search').keyup();

  }
  componentDidMount() {
    $("#success-alert").hide();
    $("#danger-alert").hide();



    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#tabladata tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }

  HandlePageByRole = async () => {
    let data = {
      pagina: "8",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true, // this should be inside the options object
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState({
            bitOpen: res.data.data[0].bitOpen,
            bitInsert: res.data.data[0].bitInsert,
            bitEdit: res.data.data[0].bitEdit,
            bitDelete: res.data.data[0].bitDelete,
          }, () => {
            if (this.state.bitOpen) {
              console.warn("Tienes todos los permisos");

              return
            } else {
              Swal.fire({
                title: 'Información',
                text: 'No tiene los permisos necesarios para acceder a esta página',
                icon: 'info',
                timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
                showConfirmButton: false,
                allowOutsideClick: false, // Prevents clicking outside the modal to close it
                didOpen: () => {
                  Swal.showLoading(); // Shows the loading spinner
                },
                timer: 3000 // Time before redirecting (3 seconds)
              }).then(() => {
                // This will execute after the modal finishes showing
                window.location.href = "/principal"; // Redirect to another page
              });
            }

          })
        }

      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };


  //Listar proyectos
  listarProyectos() {
    try {
      axios.get(process.env.REACT_APP_SERVER + '/operaciones/getproyectos', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      }).then((res) => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log("Proyectos list: ", res.data.data);
          this.setState({ proyectos: res.data.data, selectedProyecto: res.data.data[0].IDProyecto }, () => {
            this.ListarInfo();
          });
        }
      })
    } catch (error) {
      console.log((error));
    }
  }


  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarInfo() {
    this.setState({ listInfo: [], showPreloader: true });
    let data =
    {
      "filtro": "",
      "orden1": this.state.orden1,
      "orden2": this.state.orden2,
      "proyecto": this.state.selectedProyecto,
    }
    console.log(data);
    const json = JSON.stringify(data);
    axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportescontroldevice', json, {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listInfo: res.data.data, showPreloader: false });
          //this.setState({ eventoseleccionado: res.data.data[0].ID_Evento });
        }
      })
  }

  handleInputChange = (event, inputId) => {
    // Actualizar el estado con el nuevo valor del campo de entrada
    this.setState({ [inputId]: event.target.value });
  };

  handleSelectProyectos = (event) => {

    const selectedValue = event.target.value;
    console.log("Proyecto seleccionado:", selectedValue);

    // Aquí puedes hacer lo que necesites con el proyecto seleccionado
    this.setState({ selectedProyecto: selectedValue }, () => {
      this.ListarInfo(); // Llamar a la función ListarInfo cuando el estado esté actualizado

      // Cerrar el offcanvas usando Bootstrap's hide method
      const offcanvasElement = this.offcanvasRef.current;
      const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
      offcanvas.hide();
    });

  }

  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">


            <div className="layout-page">
              <Barra />
              {this.state.bitOpen && (
                <div className="content-wrapper">

                  <div className="container-fluid flex-grow-1 container-p-y">
                    <MDBRow>
                      <MDBCol md='7'>
                        <label
                          className="fw-bold mb-4 label_trafico"
                          style={{ fontSize: "22px", marginRight: "10px" }}
                        >
                          {" "}
                          Control de Dispositivos
                        </label>
                      </MDBCol>
                      <MDBCol md='4'>
                        <div className="input-group col-md-12">
                          <span className="input-group-text" id="b1"><FaSearch /></span>
                          <input id="search" type="text" className="form-control" placeholder="Filtrar" aria-describedby="b1"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md='1'>
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd"
                        >
                          <FaFilter />
                        </button>
                      </MDBCol>
                    </MDBRow>

                    <div className="card cardContainerControlDevice">
                      <div className="table-responsive text-nowrap" style={{ height: `calc(100vh - 180px)`, overflowY: "auto", display: "flex", flexDirection: "column", overflowX: 'auto' }}>
                        <table className="table table-hover" id="tabla" style={{ tableLayout: 'auto', fontSize: '12px' }}>
                          <thead className="FondoHeaderTabla2">
                            <tr>
                              <th style={{ color: '#ffffff' }}>Device</th>
                              <th style={{ color: '#ffffff' }}>Ul.Contrato</th>
                              <th style={{ color: '#ffffff' }}>Cliente</th>
                              <th style={{ color: '#ffffff' }}>Ruta</th>
                              <th style={{ color: '#ffffff' }}>Ul.Actualizacion</th>
                              <th style={{ color: '#ffffff' }}>Ul.Guardado</th>
                              <th style={{ color: '#ffffff' }}>Tiempo</th>
                              <th style={{ color: '#ffffff' }}>Diff</th>
                              <th style={{ color: '#ffffff' }}>Ul.Operador</th>
                              <th style={{ color: '#ffffff' }}>Ul.Server</th>
                              <th style={{ color: '#ffffff' }}>FW</th>
                              <th style={{ color: '#ffffff' }}>Config</th>
                              <th style={{ color: '#ffffff' }}>Ln.APN1</th>
                              <th style={{ color: '#ffffff' }}>Ln.APN2</th>
                              <th style={{ color: '#ffffff' }}>Hrs.Viaje</th>
                              <th style={{ color: '#ffffff' }}>Cant.Rep</th>
                              <th style={{ color: '#ffffff' }}>Rep/Hora</th>
                              <th style={{ color: '#ffffff' }}>VoltIni</th>
                              <th style={{ color: '#ffffff' }}>Batt</th>
                              <th style={{ color: '#ffffff' }}></th>
                              <th style={{ color: '#ffffff' }}>Cmo Volt</th>
                              <th style={{ color: '#ffffff' }}>Horas/Volt</th>
                              <th style={{ color: '#ffffff' }}>Rep/Volt</th>
                              <th style={{ color: '#ffffff' }}>ProyBatt</th>
                              <th style={{ color: '#ffffff' }}>APN1</th>
                              <th style={{ color: '#ffffff' }}>APN2</th>
                              <th style={{ color: '#ffffff' }}>Ul.Posicion</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0" id="tabladata">
                            {this.loadFillData([])}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="content-backdrop fade"></div>
                </div>
              )}
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la información.
        </div>

        <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasEnd" aria-labelledby="offcanvasScrollingLabel" ref={this.offcanvasRef}>
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Filtros</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Ordenamiento de equipos </label>
                  <select className="form-select" defaultValue={this.state.orden1}
                    onChange={e => this.handleInputChange(e, 'orden1')}>
                    <option value="Todos">Todos</option>
                    <option value="Activos">Activos</option>
                    <option value="Inactivos">Inactivos</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Ordenamiento</label>
                  <select className="form-select" defaultValue={this.state.orden2}
                    onChange={e => this.handleInputChange(e, 'orden2')}>
                    <option value="0">ALFABETICAMENTE ASCENDENTE</option>
                    <option value="1">ALFABETICAMENTE DESCENDENTE</option>
                    <option value="2">POR FECHA/HORA ACTUALIZACION ASCENDENTE</option>
                    <option value="3">POR FECHA/HORA ACTUALIZACION DESCENDENTE</option>
                    <option value="4">POR FECHA/HORA GUARDADO ASCENDENTE</option>
                    <option value="5">POR FECHA/HORA GUARDADO DESCENDENTE</option>
                    <option value="6">POR DIFERENCIA DE GUARDADO ASCENDENTE</option>
                    <option value="7">POR DIFERENCIA DE GUARDADO DESCENDENTE</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Ordenamiento por proyectos </label>
                  <select className="form-select" defaultValue={this.state.proyectos[0]}
                    onChange={e => this.handleSelectProyectos(e)}>
                    {this.state.proyectos.map((proyecto, index) => (
                      <option key={index} value={proyecto.IDProyecto}>
                        {proyecto.Descripcion}
                      </option>
                    ))}
                  </select>
                </div>
              </div>


              <div className="mb-3 d-flex flex-column align-items-end">
                <button type="button" className="btn btn-outline-secondary justify-content-end" onClick={this.ListarInfo} data-bs-dismiss="offcanvas">
                  <span className="tf-icons bx bx-search"></span>&nbsp; Consultar
                </button>
              </div>
            </div>
          </div>
        </div>



      </>


    );
  }

  loadFillData(array) {
    if (this.state.showPreloader) {
      return (
        <>
          <tr>
            <td colSpan="15" className="text-center">
              <PreloaderHistorico />
            </td>
          </tr>
        </>
      )
    }

    if (this.state.listInfo.length === 0) {
      return (
        <tr>
          <td colSpan="15" className="text-center">
            <i className='bx bxs-spreadsheet bx-lg bx-tada-hover'></i>
            <p>No hay registros disponibles</p>
          </td>
        </tr>
      )
    }

    return this.state.listInfo.map((data) => {

      return (
        <tr key={data.DeviceID}>
          <td className="prueba"><a href={`controldevice?device=${data.DeviceID}`}><strong>{data.DeviceID}</strong></a></td>
          <td>{data.UltContrato}</td>
          <td>{data.Cliente}</td>
          <td>{data.Ruta}</td>
          <td>{formattedGlobalDate(data.eventDateTime)}</td>
          <td>{formattedGlobalDate(data.LastSaved)}</td>
          <td>{data.Tiempo}</td>
          <td>{data.Diff}</td>
          <td>{data.Compania}</td>
          <td>{data.UltServer}</td>
          <td>{data.FW}</td>
          <td>{data.ConfigDevice}</td>
          <td>{data.LApn1}</td>
          <td>{data.LApn2}</td>
          <td>{data.HorasViaje}</td>
          <td>{data.CantReportes}</td>
          <td>{data.RepxHora}</td>
          <td>{data.VoltageInit}</td>
          <td>{data.voltage}</td>
          <td>icono**</td>
          <td>{data.DeltaBateria}</td>
          <td>{data.HoraxVolt}</td>
          <td>{data.ReporxVolt}</td>
          <td>{data.ProyBat}</td>
          <td>{data.Apn1}</td>
          <td>{data.Apn2}</td>
          <td>{data.Position}</td>
        </tr>
      )
    });
    $('.dropdown-toggle').dropdown();

  }
}

export default ControlDevices;
