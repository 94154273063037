// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-navbar-principal {
    background-color: #fff !important;
    /* Add any additional styling you need */
    padding: 1rem; /* Example: add padding */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Example: add shadow for depth */
    /* border-bottom: 1px solid #dee2e6; Example: border for separation */
  }

  .custom-navbar-admin{
    background-color: #ebebebd1 !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Css/navbarPrincipal.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,wCAAwC;IACxC,aAAa,EAAE,yBAAyB;IACxC,4EAA4E;IAC5E,qEAAqE;EACvE;;EAEA;IACE,sCAAsC;EACxC","sourcesContent":[".custom-navbar-principal {\n    background-color: #fff !important;\n    /* Add any additional styling you need */\n    padding: 1rem; /* Example: add padding */\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Example: add shadow for depth */\n    /* border-bottom: 1px solid #dee2e6; Example: border for separation */\n  }\n\n  .custom-navbar-admin{\n    background-color: #ebebebd1 !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
