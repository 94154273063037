import React, { useEffect, useState } from 'react';
import '../Pages/Css/switchButoon.css';

const SwitchButton2 = ({ checked, onChange, type }) => {
    // Sincroniza el estado de isActive con la prop checked correctamente
    const [isActive, setIsActive] = useState(checked);

    useEffect(() => {
        setIsActive(checked);
    }, [checked]); // Agrega checked como dependencia para mantenerlo sincronizado

    const toggleSwitch = () => {
        const newValue = !isActive;
        setIsActive(newValue);
        onChange(newValue);
    };

    return (
        <>
            <button
                className={`ToggleSwitch ${isActive ? 'active' : ''}`}
                onClick={toggleSwitch}
            >
                <span className="ToggleSwitchInner">
                    <span className="ToggleSwitchHandle" />
                </span>
            </button>
            {type}
        </>
    );
};

export default SwitchButton2;
