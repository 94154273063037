import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useState } from 'react'
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from '../../utils/axiosConfig';

const FormUpdatePassword = ({ onClose }) => {
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async () => {
        if (password !== confirmationPassword) {
            Swal.fire({
                title: '¡Error!',
                text: 'Las contraseñas no coinciden',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
            });
        } else {
            const data = {
                Pwd: password,
            };
            try {
                const res = await axios.post(process.env.REACT_APP_SERVER + '/usuarios/updateusuariopass', JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                    },
                    withCredentials: true,
                });
                if (res.data.success) {
                    onClose();
                    Swal.fire({
                        title: '¡Exito al cambiar la contraseña!',
                        text: 'Sus cambios se guardaron exitosamente.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Aceptar',
                    });
                } else {
                    window.location = '/';
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <>
            <MDBRow>
                <MDBCol md={6}>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={4}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">contraseña</label>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={6} >
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <input className="Input" type={showPassword ? 'text' : 'password'} id="confrimationPassword" value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '150px', paddingRight: '30px' }} />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(170px, -4px)',
                                        cursor: 'pointer',
                                        color: '#999',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className='bx bx-hide' style={{ fontSize: '20px' }}></i> // Icono de ocultar contraseña
                                    ) : (
                                        <i className='bx bx-show' style={{ fontSize: '20px' }}></i> // Icono de mostrar contraseña
                                    )}
                                </span>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={4}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">confirmar</label>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={6} >
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <input className="Input" type={showPassword ? 'text' : 'password'} id="confrimationPassword" value={confirmationPassword} onChange={(e) => setConfirmationPassword(e.target.value)} style={{ width: '150px', paddingRight: '30px' }} />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        transform: 'translate(170px, -4px)',
                                        cursor: 'pointer',
                                        color: '#999',
                                    }}
                                >
                                    {showPassword ? (
                                        <i className='bx bx-hide' style={{ fontSize: '20px' }}></i> // Icono de ocultar contraseña
                                    ) : (
                                        <i className='bx bx-show' style={{ fontSize: '20px' }}></i> // Icono de mostrar contraseña
                                    )}
                                </span>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow >
            <MDBRow style={{ display: 'flex', justifyContent: 'center', marginTop: '18px' }}>
                <Button
                    onClick={onSubmit}
                    className="Button blue"
                    style={{ width: '30%' }}  // El botón ocupará el 100% del contenedor
                >
                    Actualizar
                </Button>
            </MDBRow>
        </>
    )
}

export default FormUpdatePassword
