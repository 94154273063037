import React from 'react';

import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Css/themes/base/all.css';
import logo from '../logo.svg';
import '../App.css';
import './Css/trafico.css';
import Menu from '../Modulos/Menu';
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import axios from '../utils/axiosConfig';
import { formattedGlobalDate } from './../utils/formattedDates';
import PreloaderHistorico from '../components/preloaders/preloader-historico';

const google = window.google;
var table;
let deviceValue = "";
var miStorage = window.localStorage;
class ControlDevice extends React.Component {

  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    deviceValue = params.get('device');
    if (deviceValue != null) {
      this.mapRef = React.createRef();
      this.ListarInfo = this.ListarInfo.bind(this);
      this.RenderMap = this.RenderMap.bind(this);
      this.state = {
        listInfo: [],
        InfoDevice: [],
        device: "",
        ultimocontrato: "",
        ultimaactualizacion: "",
        ultimoguardado: "",
        ultimaposicion: "",
        bateria: "",
        estado: "",
        servidor: "",
        apn1: "",
        lineaapn1: "",
        apn2: "",
        fw: "",
        tipo: "",
        fechainicio: "",
        fechafin: "",
        maxDateDesde: '',
        minDateHasta: '',
        maxDateHasta: '',
        showPreloader: false,

      };
      this.ListarInfoDevice();
    } else {
      console.log('El parámetro "device" no está presente en la URL');
      window.location = "/controldevices";
    }


  }

  componentDidUpdate() {
    $('#search').keyup();

  }
  componentDidMount() {
    $("#success-alert").hide();
    $("#danger-alert").hide();

    this.RenderMap();

    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#tabladata tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
    this.currentDate();
  }

  RenderMap() {
    console.log("entro al map");
    const coords = { lat: 10.992716649650994, lng: -74.80259436365039 };
    try {
      const el = document.getElementById('map');

      if (el) {
        this.mapRef.current = new google.maps.Map(el, {
          zoom: 16,
          center: {
            lat: coords.lat,
            lng: coords.lng,
          },
        });

        return this.mapRef.current;
      } else {
        return null;
      }
    } catch (error) {
      window.history.go(0);
    }

  }


  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarInfo() {
    this.setState({ listInfo: [] });
    let fi = new Date(this.state.fechainicio);
    let ff = new Date(this.state.fechafin);
    console.log(fi);
    if (isNaN(fi) || isNaN(ff)) {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    } else {
      let fechainiciof = fi.getFullYear() + "-" + String(fi.getMonth() + 1).padStart(2, '0') + "-" + String(fi.getDate()).padStart(2, '0') + " " + String(fi.getHours()).padStart(2, '0') + ":" + String(fi.getMinutes()).padStart(2, '0') + ":00";
      let fechafinf = ff.getFullYear() + "-" + String(ff.getMonth() + 1).padStart(2, '0') + "-" + String(ff.getDate()).padStart(2, '0') + " " + String(ff.getHours()).padStart(2, '0') + ":" + String(ff.getMinutes()).padStart(2, '0') + ":00";
      let data =
      {
        "tipo": this.state.tipo,
        "device": deviceValue,
        "inicio": fechainiciof,
        "fin": fechafinf
      }
      this.setState({ showPreloader: true });
      console.log(data);
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportescontroldevicexequipo', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(res => {
          if (res.data.success === false) {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
          } else {
            this.setState({ listInfo: res.data.data, showPreloader: false });
            this.MarkersEnMapa(res.data.data);
            //this.setState({ eventoseleccionado: res.data.data[0].ID_Evento });
          }
        }).catch((error)=>{console.log(error)});
    }
  }

  ListarInfoDevice() {
    this.setState({ InfoDevice: [] });
    let data =
    {
      "filtro": deviceValue
    }
    console.log(data);
    const json = JSON.stringify(data);
    axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportescontroldeviceunico', json, {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          if (res.data.data && res.data.data.length > 0) {
            this.setState({ InfoDevice: res.data.data });

            this.setState({
              device: res.data.data[0].DeviceID, ultimocontrato: res.data.data[0].UltContrato,
              ultimaactualizacion: res.data.data[0].eventDateTime, ultimoguardado: res.data.data[0].LastSaved,
              ultimaposicion: res.data.data[0].Position, bateria: res.data.data[0].voltage,
              estado: res.data.data[0].Estado, servidor: res.data.data[0].UltServer,
              apn1: res.data.data[0].Apn1, lineaapn1: res.data.data[0].LApn1,
              apn2: res.data.data[0].Apn2, lineaapn2: res.data.data[0].LApn2,
              fw: res.data.data[0].FW, tipo: res.data.data[0].FKLokTipoEquipo
            });
          } else {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
            this.setState({ InfoDevice: [] });
          }
        }
      }).catch((error)=>{console.log(error)});
  }

  //LIMPIAR FILTROS
  LimpiarFiltros = () => {
    this.setState({
      fechainicio: "",
      fechafin: "",
      maxDateDesde: '',
      minDateHasta: '',
      maxDateHasta: ''
    }, () => {
      this.currentDate();
    })
  }

  MarkersEnMapa(lista) {
    const promises = lista.filter(objeto => objeto.latitude !== 0 && objeto.longitude !== 0).map((objeto) => {
      return new Promise((resolve) => {
        resolve({ lat: objeto.latitude, lng: objeto.longitude, id: objeto.ID, icon: objeto.lock });
      });
    });
    Promise.all(promises)
      .then((flightPlanCoordinates) => {
        for (let i = 0; i < flightPlanCoordinates.length - 1; i++) {
          const directionArrow = new google.maps.Polyline({
            path: [flightPlanCoordinates[i + 1], flightPlanCoordinates[i]],
            geodesic: true,
            strokeColor: "#88A0CF",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                  scale: 1,
                  strokeColor: "#082357"
                },
                offset: "50%",
              },
            ],
            map: this.mapRef.current,
          });
        }

        const markerslist = flightPlanCoordinates.map((coordinate, index) => {
          var iconurl = './truckclose.png';
          if (coordinate.icon == 0) {
            iconurl = './truckopen.png';
          }
          const marker = new google.maps.Marker({
            position: coordinate,
            map: this.mapRef.current,
            id: coordinate.id,
            ubicacion: coordinate.ubicacion,
            icon: {
              url: iconurl,
              scaledSize: new google.maps.Size(30, 30)
            },
          });

          return { marker };

        });

        const bounds = new google.maps.LatLngBounds();
        markerslist.forEach(({ marker }) => bounds.extend(marker.getPosition()));
        this.mapRef.current.fitBounds(bounds);
      });
  }

  currentDate = () => {
    const now = new Date();
    // Formatear la fecha para el campo de inicio (00:00)
    const currendDate = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, '0') + "-" + String(now.getDate()).padStart(2, '0') + "T00:00";
    this.setState({
      maxDateDesde: currendDate
    }, () => { console.log("esta es la fecha actual: ", this.state.maxDateDesde) });
  }

  handleInputChange = (event) => {
    console.log(event);
    const fechaInicio = new Date(event);
    let inicio = fechaInicio.getFullYear() + "-" +
      String(fechaInicio.getMonth() + 1).padStart(2, '0') + "-" +
      String(fechaInicio.getDate()).padStart(2, '0') + "T00:00";
    console.log("Inicio formateado:", inicio);
    let fin = fechaInicio.getFullYear() + "-" +
      String(fechaInicio.getMonth() + 1).padStart(2, '0') + "-" +
      String(fechaInicio.getDate()).padStart(2, '0') + "T23:59";
    console.log("Fin formateado:", fin);

    this.setState({
      fechainicio: inicio,
      fechafin: fin
    })

  };


  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">


            <div className="layout-page">
              <Barra />
              <div className="content-wrapper">

                <div className="container-fluid flex-grow-1 container-p-y">
                  <MDBRow>
                    <MDBCol md='7' >
                      <h4 className="fw-bold mb-4 controlDeviceTitle">Control Device</h4>
                    </MDBCol>
                    <MDBCol md='4'>
                      <div className="input-group col-md-12">
                        <span className="input-group-text" id="b1"><FaSearch /></span>
                        <input id="search" type="text" className="form-control" placeholder="Filtrar" aria-describedby="b1"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md='1'>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEnd"
                        aria-controls="offcanvasEnd"
                      >
                        <FaFilter />
                      </button>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md='7'>
                      <MDBRow>
                        <MDBCol md='2'><b>DeviceID:</b></MDBCol><MDBCol md='4'>{this.state.device}</MDBCol>
                        <MDBCol md='2'><b>Ul.Contrato:</b></MDBCol><MDBCol md='4'>{this.state.ultimocontrato}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Ul.Actualizacion:</b></MDBCol><MDBCol md='4'>{formattedGlobalDate(this.state.ultimaactualizacion)}</MDBCol>
                        <MDBCol md='2'><b>Ul.Guardado:</b></MDBCol><MDBCol md='4'>{formattedGlobalDate(this.state.ultimoguardado)}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Bateria:</b></MDBCol><MDBCol md='4'>{this.state.bateria}</MDBCol>
                        <MDBCol md='2'><b>Ul.Posicion:</b></MDBCol><MDBCol md='4'>{this.state.ultimaposicion}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Estado:</b></MDBCol><MDBCol md='4'>{this.state.estado}</MDBCol>
                        <MDBCol md='2'><b>Servidor:</b></MDBCol><MDBCol md='4'>{this.state.servidor}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Apn Principal:</b></MDBCol><MDBCol md='4'>{this.state.apn1}</MDBCol>
                        <MDBCol md='2'><b>Linea Apn Ppal:</b></MDBCol><MDBCol md='4'>{this.state.lineaapn1}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Apn Secundaria:</b></MDBCol><MDBCol md='4'>{this.state.apn2}</MDBCol>
                        <MDBCol md='2'><b>Linea Apn Sec:</b></MDBCol><MDBCol md='4'>{this.state.lineaapn2}</MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md='2'><b>Firmware:</b></MDBCol><MDBCol md='4'>{this.state.fw}</MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md='5'><div id="map" style={{ border: '1px solid #c4c4c4', height: '240px' }} /></MDBCol>
                  </MDBRow>
                  <div className="card cardControlDevice">
                    <div className="table-responsive text-nowrap" style={{ height: `calc(100vh - 410px)`, overflowY: "auto", display: "flex", flexDirection: "column", overflowX: 'auto' }}>
                      <table className="table table-hover" id="tabla" style={{ tableLayout: 'auto', fontSize: '12px' }}>
                        <thead className="FondoHeaderTabla2">
                          <tr>
                            <th style={{ color: '#ffffff' }}>Longitud</th>
                            <th style={{ color: '#ffffff' }}>Latitud</th>
                            <th style={{ color: '#ffffff' }}>Ul.Actualizacion</th>
                            <th style={{ color: '#ffffff' }}>Ul.Guardado</th>
                            <th style={{ color: '#ffffff' }}>Diff</th>
                            <th style={{ color: '#ffffff' }}>Estado</th>
                            <th style={{ color: '#ffffff' }}>Evento</th>
                            <th style={{ color: '#ffffff' }}>DeviceEvento</th>
                            <th style={{ color: '#ffffff' }}>Gps</th>
                            <th style={{ color: '#ffffff' }}>CSQ</th>
                            <th style={{ color: '#ffffff' }}>Bateria</th>
                            <th style={{ color: '#ffffff' }}>Vel.</th>
                            <th style={{ color: '#ffffff' }}>Operador</th>
                            <th style={{ color: '#ffffff' }}>Source</th>
                            <th style={{ color: '#ffffff' }}>Server</th>
                            <th style={{ color: '#ffffff' }}>Ul.Posicion</th>
                            <th style={{ color: '#ffffff' }}>Ul.Geo</th>
                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0" id="tabladata">
                          {this.loadFillData([])}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la información.
        </div>

        <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasEnd" aria-labelledby="offcanvasScrollingLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Filtros</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Hora/Fecha Inicio:</label>
                  <input type="datetime-local" className="form-control" style={{ width: '88%' }}
                    value={this.state.fechainicio}
                    max={this.state.maxDateDesde}
                    onChange={(e) => this.handleInputChange(e.target.value)} />
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Hora/Fecha Fin:</label>
                  <input type="datetime-local" className="form-control" style={{ width: '88%' }}
                    value={this.state.fechafin}
                    min={this.state.minDateHasta}
                    max={this.state.maxDateHasta}
                    disabled
                  // onChange={e => this.handleInputChange(e, 'fechafin')} 
                  />
                </div>
              </div>

              <div className="mb-3 d-flex flex-column align-items-end">
                <button type="button" className="btn btn-outline-secondary justify-content-end" onClick={this.ListarInfo} data-bs-dismiss="offcanvas">
                  <span className="tf-icons bx bx-search"></span>&nbsp; Consultar
                </button>
              </div>

            </div>
          </div>
        </div>



      </>


    );
  }

  loadFillData(array) {


    if (this.state.showPreloader) {
      return (
        <>
          <tr>
            <td colSpan="16" className="text-center">
              <PreloaderHistorico />
            </td>
          </tr>
        </>
      )
    }

    if (this.state.listInfo.length === 0) {
      return (
        <tr>
          <td colSpan="16" className="text-center">
            <i className='bx bxs-spreadsheet bx-lg bx-tada-hover'></i>
            <p>No hay registros disponibles</p>
          </td>
        </tr>
      )
    }

    console.log("LISTINFOOOOO:", this.state.listInfo);
    return this.state.listInfo.map((data) => {
      return (
        <tr key={data.ID}>
          <td>{data.longitude}</td>
          <td>{data.latitude}</td>
          <td>{formattedGlobalDate(data.UltActualizacion)}</td>
          <td>{formattedGlobalDate(data.UltActualizacion)}</td>
          <td>{data.DiffTime}</td>
          <td>{data.lock ? 'cerrado' : 'apertura'}</td>
          <td>{data.event}</td>
          <td>{data.device}</td>
          <td>{data.gpsStatus}</td>
          <td>{data.csq}</td>
          <td>{data.voltages}</td>
          <td>{data.speed}</td>
          <td>{data.Compania}</td>
          <td>{data.source}</td>
          <td>{data.servername}</td>
          <td>{data.position}</td>
          <td>{data.Nombre}</td>
        </tr>
      )
    });
    $('.dropdown-toggle').dropdown();

  }
}

export default ControlDevice;
