import React, { useEffect, useRef, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import { CheckIcon, Cross2Icon, ChevronDownIcon, ChevronUpIcon, DividerHorizontalIcon } from '@radix-ui/react-icons';
import '../../Pages/Css/solicitudes.css';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import TooltipComponent from '../../componentes/radix/Tooltip';
import Checbox from '../../componentes/radix/Checkbox';
import SwitchButton from '../../componentes/SwitchButton';
import Swal from 'sweetalert2';
import axios from '../../utils/axiosConfig';

const FormSolicitudesLog = ({ clientes, onClose }) => {

    const refs = {
        fechaHoraCita: useRef(null),
        fechaHoraDescargue: useRef(null),
        horaTransitoStart: useRef(null),
        horaTransitoEnd: useRef(null),
    };

    const [dates, setDates] = useState({
        fechaHoraCita: '',
        fechaHoraDescargue: '',
    });

    const [times, setTimes] = useState({
        horaTransitoStart: '',
        horaTransitoEnd: '',
    });

    const [checked, setChecked] = useState(false);
    // const [clientes, setClientes] = useState([]);
    const userSolicitud = localStorage.getItem('username');
    const [clientesConsulta, setClientesConsulta] = useState([]);
    const [clientesConsulta1, setClientesConsulta1] = useState([]);
    const [clientesConsulta2, setClientesConsulta2] = useState([]);
    const [rutas, setRutas] = useState([]);
    const [tipoContratos, setTipoContratos] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [transpotadoras, setTranspotadoras] = useState([]);
    const [destinosAutorizados, setDestinosAutorizados] = useState([]);
    const [unidadCargas, setUnidadCarga] = useState([]);
    const [instaladores, setInstaladores] = useState([]);
    const [categoriasServicio, setCategoriasServicio] = useState([]);
    //Handle changes selects
    // const [idClienteExterno, setIdClienteExterno]= useState(0);
    // const [valueClienteExterno, setValueClienteExterno]= useState(null);
    const [selectedCliente, setSelectedCliente] = useState(null);
    const [selectedClienteConsulta, setSelectedClienteConsulta] = useState(null);
    const [selectedClienteConsulta1, setSelectedClienteConsulta1] = useState(null);
    const [selectedClienteConsulta2, setSelectedClienteConsulta2] = useState(null);
    const [selectedRuta, setSelectedRuta] = useState(null);
    const [selectedTipoContrato, setSelectedTipoContrato] = useState(0);
    const [selectedServicio, setSelectedServicio] = useState(null);
    const [selectedTransportador, setSelectedTransportador] = useState(null);
    const [selectedDestinoAutorizado, setSelectedDestinoAutorizado] = useState(null);
    const [selectedTipoUnidadCarga, setSelectedTipoUnidadCarga] = useState(null);
    const [selectedInstalador, setSelectedInstalador] = useState(null);
    const [selectedCatServicio, setSelectedCatServicio] = useState(2);//2 es para servicio comercial

    const [nroSolicitud, setNroSolicitud] = useState('');
    const [placaTruck, setPlacaTruck] = useState('');
    const [marcaTruck, setMarcaTruck] = useState('');
    const [colorTruck, setColorTruck] = useState('');
    const [referencia, setReferencia] = useState(null);
    const [placaRemolque, setPlacaRemolque] = useState('');
    const [nombreConductor, setNombreConductor] = useState(null);
    const [nombreAcompanante, setNombreAcompanante] = useState(null);
    const [diConductor, setDiConductor] = useState(null);
    const [diAcompanante, setDiAcompanante] = useState(null);
    const [cellphoneAcompanante, setCellphoneAcompanante] = useState(null);
    const [cellphoneConductor, setCellphoneConductor] = useState(null);
    const [nroContenedor, setNroContenedor] = useState(null);
    const [digitoVerificacion, setDigitoVerificacion] = useState(null);
    const [contacto, setContacto] = useState(null);
    const [fHoraCita, setFHoraCita] = useState(null);
    const [fHoraCitaFormated, setFHoraCitaFormated] = useState(null);
    const [fHoraDescargue, setFHoraDescargue] = useState(null);
    const [fHoraDescargueFormated, setFHoraDescargueFormated] = useState(null);
    const [horaTransitoStart, setHoraTransitoStart] = useState(null);
    const [horaTransitoEnd, setHoraTransitoEnd] = useState(null);
    const [restriccion, setRestriccion] = useState(false);
    const [solicitante, setSolicitante] = useState(null);
    const [datosEntrega, setDatosEntrega] = useState(null);
    const [causalCat, setCausalCat] = useState(null);
    const [notas, setNotas] = useState(null);
    const ciudades = [
        { id: 1, nombre: "Consulta  1 example" },
        { id: 2, nombre: "Consulta  2 example" },
        { id: 3, nombre: "Consulta  3 example" },
        { id: 4, nombre: "Consulta  4 example" },
        { id: 5, nombre: "Consulta  5 example" },
        // Agrega más ciudades según sea necesario
    ];

    const handleCheckboxChange = (newValue) => {
        setRestriccion(newValue);
        console.log(newValue);
    };

    //GENERAR NUEVO NUMERO SOLICTUD
    const GenerarNumeroSolicitud = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getnumerosolicitudnuevo", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setNroSolicitud(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR CLIENTES
    const ListarClientes = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaempresas", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setClientesConsulta(res.data.data);
                    setClientesConsulta1(res.data.data);
                    setClientesConsulta2(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR TIPO DE UNIDAD DE CARGA
    const ListarUnidadesCarga = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaUnidadCarga", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setUnidadCarga(res.data.data);

                }
            }).catch((err) => console.log(err));
    }

    //LISTAR TRASNPORTADORAS
    const ListarTransportadoras = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistatransportadoras", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Trasnportadoras: ", res.data.data);
                    setTranspotadoras(res.data.data);

                }
            }).catch((err) => console.log(err));
    }

    //LISTAR INSTALADORES
    const ListarInstaladores = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getlistaInstaladores", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Instaladores: ", res.data.data);
                    setInstaladores(res.data.data);

                }
            }).catch((err) => console.log(err));
    }

    //LISTAR CATEGORIAS SERVICIO
    const ListarCategoriaServicios = () => {
        axios
            .get(process.env.REACT_APP_SERVER + "/solicitudes/getcategoriasservicios", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Categoria Servicio: ", res.data.data);
                    setCategoriasServicio(res.data.data);
                }
            }).catch((err) => console.log(err));
    }


    //HANDLECHANGES VALUES
    const handleSelectChangeCliente = (event) => {
        setSelectedCliente(event.target.value);
        console.log("Id cliente seleccionado: ", event.target.value);

    };
    const handleSelectChangeRuta = (event) => {
        setSelectedRuta(event.target.value);
        console.log("Ruta cliente seleccionada: ", event.target.value);

    };

    const handleSelectChangeTipoContrato = (event) => {
        setSelectedTipoContrato(event.target.value);
        console.log("Tipo contrato selescted: ", event.target.value)
    }

    const handleSelectChangeServicio = (event) => {
        setSelectedServicio(event.target.value);
        console.log("Servicio seleccionado: ", event.target.value);
    }

    const handleSelectChangeTransportador = (event) => {
        setSelectedTransportador(event.target.value);
        console.log("Transportador seleccionado: ", event.target.value);
    }

    const handleSelectChangeDestAutorizado = (event) => {
        setSelectedDestinoAutorizado(event.target.value);
        console.log("Destino autorizado seleccionado: ", event.target.value);
    }

    const handleSelectChangeTipoUndCarga = (event) => {
        setSelectedTipoUnidadCarga(event.target.value);
        console.log("Tipo unidad de carga seleccionado: ", event.target.value);
    }

    const handleSelectChangeInstalador = (event) => {
        setSelectedInstalador(event.target.value);
        console.log("Instalador seleccionado: ", event.target.value);
    }

    const handleSelectChangeCatServicio = (event) => {
        setSelectedCatServicio(event.target.value);
        console.log("Categoria de servicio seleccionado: ", event.target.value);
    }

    const handleSelectChangeClienteConsulta = (event) => {
        setSelectedClienteConsulta(event.target.value);
        console.log("Cliente consulta seleccionado: ", event.target.value);
    }

    const handleSelectChangeClienteConsulta1 = (event) => {
        setSelectedClienteConsulta1(event.target.value);
        console.log("Cliente consulta 1 seleccionado: ", event.target.value);
    }

    const handleSelectChangeClienteConsulta2 = (event) => {
        setSelectedClienteConsulta2(event.target.value);
        console.log("Cliente consulta 2 seleccionado: ", event.target.value);
    }

    //FORMATEO FECHA Y HORA
    const handleDateTimeChangeHoraCita = (e) => {
        // Captura el valor del input
        const value = e.target.value;

        // Extrae la fecha y hora
        const [date, time] = value.split('T');

        // Agrega los segundos a la hora
        const timeWithSeconds = time.length === 5 ? `${time}:00` : time;

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${timeWithSeconds}`;

        // Actualiza los estados
        console.log('Datos de fecha antes de formatear: -->>', value);
        console.log('Datos fecha formateada: -->>', formattedDateTime);
        setFHoraCitaFormated(formattedDateTime);
    };


    const handleDateTimeChangeHoraDescargue = (e) => {
        // Captura el valor del input
        const value = e.target.value;

        // Extrae la fecha y hora
        const [date, time] = value.split('T');

        // Agrega los segundos a la hora
        const timeWithSeconds = time.length === 5 ? `${time}:00` : time;

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${timeWithSeconds}`;

        // Actualiza los estados
        console.log('Datos de fecha antes de formatear: -->>', value);
        console.log('Datos fecha formateada: -->>', formattedDateTime);
        setFHoraDescargueFormated(formattedDateTime);
        setFHoraDescargue(value);
    };


    //VALIDAR NRO CONTENEDOR
    // Function to validate container number
    const validateNroContent = (codigo) => {
        const nroContenedorLength = codigo.length;

        console.log("Value nro contenedor: ", codigo);
        console.log("Tamano nro contenedor input: ", nroContenedorLength);

        // Function to calculate the verification digit
        function fCalDig(codigo) {
            const refc = "0123456789A_BCDEFGHIJK_LMNOPQRSTU_VWXYZ";
            let nValor, nTotal = 0, nPow2 = 1;
            if (codigo.length !== 10) return ' ';
            for (let n = 0; n < 10; n++) {
                nValor = refc.indexOf(codigo.charAt(n));
                if (nValor < 0) return ' ';
                nTotal += nValor * nPow2;
                nPow2 *= 2;
            }
            nTotal = nTotal % 11;
            if (nTotal >= 10) nTotal = 0;
            return nTotal.toString();
        }

        // Function to show error using Swal
        function showError(message) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: message,
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        }


        if (nroContenedorLength === 0) {
            setDigitoVerificacion('');
        }

        if (nroContenedorLength === 10) {
            let firstFourAreStrings = true;
            let lastSixAreNumbers = true;

            // Validate that the first 4 characters are letters
            for (let index = 0; index < 4; index++) {
                if (!isNaN(codigo[index])) {
                    firstFourAreStrings = false;
                    break;
                }
            }

            // Validate that the last 6 characters are numbers
            for (let index = 4; index < nroContenedorLength; index++) {
                if (isNaN(codigo[index])) {
                    lastSixAreNumbers = false;
                    break;
                }
            }

            if (firstFourAreStrings) {
                console.log("Nro contenedor inicia con 4 caracteres de tipo string (letras)");
            } else {
                console.log("Nro contenedor no inicia con 4 caracteres de tipo string (letras)");
                showError("El número del contenedor debe tener el siguiente formato 'AAAA123456'");
            }

            if (lastSixAreNumbers) {
                console.log("Nro contenedor termina con 6 caracteres de tipo número");
            } else {
                console.log("Nro contenedor no termina con 6 caracteres de tipo número");
                showError("El número del contenedor debe tener el siguiente formato 'AAAA123456'");
            }

            if (lastSixAreNumbers && firstFourAreStrings) {
                const verificationDigit = fCalDig(codigo);
                console.log("Codigo total: ", codigo);
                console.log("Verification Digit: ", verificationDigit);
                setDigitoVerificacion(verificationDigit);
            }
        }
    };



    const handleChangePlacaTruck = (event) => {
        console.log(event.target.value);
        console.log(event.target.value.length);
        const placaLength = event.target.value.length;
        if (placaLength === 6) {
            setPlacaTruck(event.target.value);
            ObtenerInfoTruck(event.target.value);
        }
    }



    //LISTAR RUTAS
    const ListarRutas = () => {
        let dataClient = {
            empresa: selectedCliente
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaRutasNegociadas", JSON.stringify(dataClient), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setRutas(res.data.data);
                    setSelectedRuta(res.data.data[0]?.FKICRuta ?? 0); // Establece 0 si FKICRuta es undefined
                    // if (res.data.data[0] !== undefined || res.data.data[0] !== null) {
                    //     setSelectedRuta(res.data.data[0]?.FKICRuta);
                    //     console.log("FKICRUTA : ", res.data.data[0].FKICRuta);
                    // }
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR DESTINDOS AUTOIZADOSD
    const ListarDestinosAutorizados = () => {
        let dataClient = {
            empresa: selectedCliente
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaGeocercasEmpresa", JSON.stringify(dataClient), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setDestinosAutorizados(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR TIPOS DE CONTRATO
    const ListartTiposContratos = () => {
        let dataClient = {
            empresa: selectedCliente,
            ruta: selectedRuta,

        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaNegociaciones", JSON.stringify(dataClient), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log(res.data.data);
                    setTipoContratos(res.data.data);

                }
            }).catch((err) => console.log(err));
    }


    //LISTAR TIPOS DE SERVICIO
    const ListartTiposServicios = () => {
        let dataClient = {
            empresa: selectedCliente,
            ruta: selectedRuta,
            externo: selectedTipoContrato
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getlistaNegociacionesFinal", JSON.stringify(dataClient), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("*************Tipos de servicios: ", res.data.data);
                    setServicios(res.data.data);
                    if (res.data.data.length === 1) {
                        setSelectedServicio(res.data.data[0]?.IDNegociacion);
                        console.log("Selected Servicio: ", res.data.data[0]?.IDNegociacion);
                    } else {
                        setSelectedServicio(res.data.data[0]?.IDNegociacion);
                        console.log("Selected Servicio: ", res.data.data[0]?.IDNegociacion);
                    }
                    // if(tipoContratos !== undefined || tipoContratos !== null){
                    //     setSelectedTipoContrato(tipoContratos[0].IdClienteExterno);
                    //     setSelectedServicio(servicios[0].IDNegociacion);
                    // }
                }
            }).catch((err) => console.log(err));
    }

    //TRAER INFORMACION DE TRUCK POR PLACA
    const ObtenerInfoTruck = (placa) => {
        let dataPlacaTruck = {
            placa: placa
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/getobtenerVehiculo", JSON.stringify(dataPlacaTruck), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    if (res.data.data !== undefined) {
                        console.log("Informacion por placa ", res.data.data);
                        setMarcaTruck(res.data.data.marcaTruck);
                        setColorTruck(res.data.data.colorTruck);
                    }

                }
            }).catch((err) => console.log(err));
    }

    //SUBMIT
    const onSubmit = (dataSolicitud) => {
        axios
            .post(process.env.REACT_APP_SERVER + "/solicitudes/setinsertSolicitud", JSON.stringify(dataSolicitud), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    onClose();
                    Swal.fire({
                        icon: "success",
                        title: "Formulario creado con éxito",
                        text: "Solicitud cargada con éxito",
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#006699';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });

                }
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Error al crear la solicitud",
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            });
    }
    const handleSubmit = () => {
        const showErrorAlert = (message) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: message,
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        };

        if (!selectedCliente || !selectedRuta || !selectedServicio) {
            showErrorAlert("Debe llenar los campos obligatorios");
            return;
        }


        if (selectedCatServicio != 2 && !causalCat) {
            // console.log("este es el valor selecionado para categoria del servicio: ", selectedCatServicio);
            showErrorAlert("Debes poner una causal de categoria por defecto");
            return;
        }

        const data = {
            FKICEmpresa: selectedCliente, // Id cliente obligatorio
            FKICRutas: selectedRuta,//Ruta seleccionadda
            Ref: referencia, // referencia obligatoria
            ContainerNum: nroContenedor || null, // ContainerNum o null
            DigitoVerificacion: digitoVerificacion || null, // función digito validación 
            FKInstaladorId: selectedInstalador || null, // Instalador o null
            Notas: notas || null,
            NombreConductor: nombreConductor || null, // NombreConductor o null
            NitConductor: diConductor || null, // cédula conductor o null
            MovilConductor: cellphoneConductor || null, // móvilConductor o null
            FKLokTipoUnidadCarga: selectedTipoUnidadCarga || null, // tipo unidad de carga o null
            PlacaTruck: placaTruck || '', // Placa o null
            ColorTruck: colorTruck || '', // color o null
            PlacaTrailer: placaRemolque || '', // Placa trailer o null
            NombreEscolta: nombreAcompanante || null, // nombre acompañante o null
            MovilEscolta: diAcompanante || null, // móvil acompañante o null
            FKLokCategoriaServ: selectedCatServicio, // categoría servicio 
            NotasTI: causalCat || null, // Causal por categoría
            Marca: marcaTruck || '', // marca del vehículo
            FKICTransportadora: selectedTransportador || null, // Transportadora o null
            FKICEmpresaConsulta: selectedClienteConsulta || null, // clientes o null
            bitRestriccion: restriccion || false, // checkbox restricción
            HoraInicioR: times.horaTransitoStart || null, // Hora inicio
            HoraFinR: times.horaTransitoEnd || null, // Hora fin
            FKICEmpresaConsulta2: selectedClienteConsulta1 || null, // Clientes o null
            FKICEmpresaConsulta3: selectedClienteConsulta2 || null,
            FKLokEstados: 2, // Estados 
            FechaHoraCita: dates.fechaHoraCita || null, // fecha hora cita
            FechaHoraCitaDescargue: dates.fechaHoraDescargue || null, // fecha hora descargue
            NotasDatosEntrega: datosEntrega || null, // Datos de entrega o null
            UserSolicitud: userSolicitud, // Username local storage
            FKNegociacion: selectedServicio, // tipo contrato obligatorio
            Solicitante: solicitante || null, // solicitante o null
            Contacto: contacto || null, // contacto o null
            FKCercaAutorizada: selectedDestinoAutorizado || null // destino autorizado o null
        };

        console.log('Data antes de subir : ', data);
        onSubmit(data);
    };


    useEffect(() => {
        ListarClientes();
        GenerarNumeroSolicitud();
        ListarTransportadoras();
        ListarUnidadesCarga();
        ListarInstaladores();
        ListarCategoriaServicios();
        console.log("clientes desde el modal ", clientes);
        if (clientes.length === 1) {
            setSelectedCliente(clientes[0]?.IdEmpresa);
        }
    }, []);

    useEffect(() => {
        ListarDestinosAutorizados();
        ListarRutas();
    }, [selectedCliente]);

    useEffect(() => {
        ListartTiposContratos();
    }, [selectedCliente, selectedRuta]);

    useEffect(() => {
        ListartTiposServicios();
        console.warn("Valor de tipoContrato: ", selectedTipoContrato);
        console.warn("Valor de Cliente: ", selectedCliente);
        console.warn("Valor de Ruta: ", selectedRuta);
        console.warn("Valor de Servicio: ", selectedServicio);
    }, [selectedTipoContrato, selectedCliente, selectedRuta]);

    useEffect(() => {
        // Obtener la hora y minuto actuales
        const now = new Date();
        const currentDateTime = now.toISOString().slice(0, 16).replace('T', ' ');
        const currentTime = now.toTimeString().slice(0, 5);

        // Configurar el idioma a inglés
        $.datetimepicker.setLocale('en');

        // Inicializar el datetimepicker para cada input con la hora actual
        $(refs.fechaHoraCita.current).datetimepicker({
            format: 'Y-m-d H:i',
            step: 30,
            value: currentDateTime, // Establecer el valor inicial con la hora actual
            timepicker: true,
            datepicker: true,
            onChangeDateTime: (dp, $input) => {
                console.log("fHoraCita: ", $input.val());
                setDates((prevState) => ({
                    ...prevState,
                    fechaHoraCita: $input.val(),
                }));
            }
        });

        $(refs.fechaHoraDescargue.current).datetimepicker({
            format: 'Y-m-d H:i',
            step: 30,
            value: currentDateTime, // Establecer el valor inicial con la hora actual
            timepicker: true,
            datepicker: true,
            onChangeDateTime: (dp, $input) => {
                console.log("fHoraDescargue: ", $input.val());
                setDates((prevState) => ({
                    ...prevState,
                    fechaHoraDescargue: $input.val(),
                }));
            }
        });

        // Inicializar el datetimepicker para solo hora
        $(refs.horaTransitoStart.current).datetimepicker({
            datepicker: false, // Desactivar selección de fecha
            format: 'H:i',
            value: currentTime, // Establecer la hora actual como valor inicial
            step: 15,
            onChangeDateTime: (dp, $input) => {
                console.log("horaTrasnitoStart: ", $input.val());
                setTimes((prevState) => ({
                    ...prevState,
                    horaTransitoStart: $input.val(),
                }));
            }
        });

        $(refs.horaTransitoEnd.current).datetimepicker({
            datepicker: false, // Desactivar selección de fecha
            format: 'H:i',
            value: currentTime, // Establecer la hora actual como valor inicial
            step: 15,
            onChangeDateTime: (dp, $input) => {
                console.log("horaTrasnitoEnd: ", $input.val());
                setTimes((prevState) => ({
                    ...prevState,
                    horaTransitoEnd: $input.val(),
                }));
            }
        });

        // Limpieza al desmontar el componente
        return () => {
            $(refs.fechaHoraCita.current).datetimepicker('destroy');
            $(refs.fechaHoraDescargue.current).datetimepicker('destroy');
            $(refs.horaTransitoStart.current).datetimepicker('destroy');
            $(refs.horaTransitoEnd.current).datetimepicker('destroy');
        };
    }, []);

    return (
        <div >
            <MDBRow style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <MDBCol md={6}>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="solicitud">Nro. Solicitud</label>
                                {/* <TooltipComponent message={'Acá iria el número que identifica la solicitud en curso '} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7} >
                            <input className="Input" id="name" defaultValue={nroSolicitud} disabled />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="cliente">Cliente</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Cliente que hará la solicitud'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeCliente} >
                                <option>{'-No asignado-'}</option>
                                {clientes.map((cliente) => (
                                    <option key={cliente.IdEmpresa} value={cliente.IdEmpresa}>
                                        {cliente.NombreEmpresa}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="ruta">Ruta</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                {/* <TooltipComponent message={'Selecciona la ruta para la solicitud'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeRuta} >
                                {rutas.length === 0 ? (
                                    <option>{'-No asignado-'}</option>) : (
                                    rutas.map((ruta) => (
                                        <option key={ruta.FKICRuta} value={ruta.FKICRuta}>
                                            {ruta.DescripcionRuta}
                                        </option>
                                    ))
                                )}
                            </select>
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoContrato">Tipo contrato</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                <TooltipComponent message={'Selecciona el tipo de contrato a solicitar'} />
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeTipoContrato}>
                                <option value={0}>{'-NO-'}</option>
                                {tipoContratos.map((contrato) => (
                                    <option key={contrato.IdClienteExterno} value={contrato.IdClienteExterno}>
                                        {contrato.Descripcion}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="servicio">Servicio</label>
                                <span style={{ color: 'red', fontSize: 15 }}>*</span>
                                <TooltipComponent message={'Servicio a solicitar y servicios disponibles para el tipo de contrato'} />
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeServicio}>
                                {servicios.length === 0 ? (
                                    <option>{'-No asignado-'}</option>
                                ) : (
                                    servicios.map((servicio) => (
                                        <option key={servicio.IDNegociacion} value={servicio.IDNegociacion}>
                                            {servicio.Descripcion}
                                        </option>
                                    ))

                                )}
                            </select>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="referencia">Referencia</label>
                                {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}
                                <TooltipComponent message={'Acá va la referencia de la solicitud'} />
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="referencia" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="placaTruck">Placa Truck</label>
                                {/* <TooltipComponent message={'Acá va la placa del vehículo'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="placaTruck" placeholder='ABC123' onChange={(e) => setPlacaTruck(e.target.value)} onKeyUp={handleChangePlacaTruck} />

                        </MDBCol>
                    </MDBRow>


                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="marca">Marca</label>
                                {/* <TooltipComponent message={'Acá va la marca del vehículo'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="marca" value={marcaTruck} onChange={(e) => setMarcaTruck(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="color">Color</label>
                                {/* <TooltipComponent message={'Describe el color del vehículo para identificarlo'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="color" value={colorTruck} onChange={(e) => setColorTruck(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="color">Placa Remolque</label>
                                {/* <TooltipComponent message={'Describe el color del vehículo para identificarlo'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="placaRemolque" value={placaRemolque} onChange={(e) => setPlacaRemolque(e.target.value)} />
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="cedulaConductor">Nombre Conductor</label>
                                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="nombreConductor" type="text" value={nombreConductor} onChange={(e) => setNombreConductor(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="cedulaConductor">Cédula Conductor</label>
                                {/* <TooltipComponent message={'Acá va la cédula del conductor'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="cedulaConductor" type="number" value={diConductor} onChange={(e) => setDiConductor(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="cellphonesConductor">Móvil(es) Conductor</label>
                                {/* <TooltipComponent message={'Digita el(los) número(s) de contacto del conductor'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="cellphonesConductor" value={cellphoneConductor} onChange={(e) => setCellphoneConductor(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="nombreAcompañante">Nombre Acompañante</label>
                                {/* <TooltipComponent message={'Acá va el nombre del acompañante'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="nombreAcompañante" value={nombreAcompanante} onChange={(e) => setNombreAcompanante(e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="cellphonesAcompañante">Móvil(es) Acompañante</label>
                                {/* <TooltipComponent message={'Digita el(los) número(s) de contacto del acompañante'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="cellphonesAcompañante" value={cellphoneAcompanante} onChange={(e) => setCellphoneAcompanante(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>

                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="destinoAutorizado">Transportador</label>
                                {/* <TooltipComponent message={'Elige la transportadora'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeTransportador}>
                                <option>{'-No asignado-'}</option>
                                {transpotadoras.map((transport) => (
                                    <option key={transport.IdTransportadora} value={transport.IdTransportadora}>
                                        {transport.NombreTranspo}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="nroContenedor">Contenedor Número</label>
                                {/* <TooltipComponent message={'Número del contendor'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10, width: '250px' }}>
                                <input className="Input" maxlength="10" value={nroContenedor} onChange={(e) => {
                                    const value = e.target.value.toUpperCase();
                                    setNroContenedor(value);
                                    validateNroContent(value);
                                }} style={{ textTransform: 'uppercase' }} />
                                <input className="Input" id="guiaContenedorNro" value={digitoVerificacion} disabled />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="destinoAutorizado">Destino Autorizado</label>
                                {/* <TooltipComponent message={'Un destino autorizado es donde se pueda descargar'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeDestAutorizado}>
                                <option>{'-No asignado-'}</option>
                                {destinosAutorizados.map((destino) => (
                                    <option key={destino.ID} value={destino.ID}>
                                        {destino.Nombre}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoUnidadCarga">Tipo Unidad de Carga</label>
                                {/* <TooltipComponent message={'El tipo de carga varía de acuerdo a'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeTipoUndCarga}>
                                <option>{'-No asignado-'}</option>
                                {unidadCargas.map((unidadCarga) => (
                                    <option key={unidadCarga.TipoUnidadCargaID} value={unidadCarga.TipoUnidadCargaID}>
                                        {unidadCarga.DescripcionTipoCarga}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="tipoUnidadCarga">Instalador</label>
                                {/* <TooltipComponent message={'La emepresa encargada de la instalacion'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeInstalador}>
                                <option>{'-No asignado-'}</option>
                                {instaladores.map((insta) => (
                                    <option key={insta.CCInstalador} value={insta.CCInstalador}>
                                        {insta.NombreInstalador}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="contacto">Contacto</label>
                                {/* <TooltipComponent message={'Contacto de cliente de consulta'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <input className="Input" id="contacto" value={contacto} onChange={(e) => setContacto(e.target.value)} />
                        </MDBCol>
                    </MDBRow>

                </MDBCol>
                <MDBCol md={6}>
                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="clienteConsultas">Cliente de Consultas</label>
                                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeClienteConsulta}>
                                <option>{'-No asignado-'}</option>
                                {clientesConsulta.map((consulta) => (
                                    <option key={consulta.IdEmpresa} value={consulta.IdEmpresa}>
                                        {consulta.NombreEmpresa}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="clienteConsultas2">Cliente de Consultas 2</label>
                                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeClienteConsulta1}>
                                <option>{'-No asignado-'}</option>
                                {clientesConsulta1.map((ciudad) => (
                                    <option key={ciudad.IdEmpresa} value={ciudad.IdEmpresa}>
                                        {ciudad.NombreEmpresa}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className="InputTooltipWrapper">
                                <label className="Label" htmlFor="clienteConsultas3">Cliente de Consultas 3</label>
                                {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <select className="InputSelect" onChange={handleSelectChangeClienteConsulta2}>
                                <option>{'-No asignado-'}</option>
                                {clientesConsulta2.map((ciudad) => (
                                    <option key={ciudad.IdEmpresa} value={ciudad.IdEmpresa}>
                                        {ciudad.NombreEmpresa}
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className='InputTooltipWrapper'>
                                <label className="Label" htmlFor="fechaHoraCita">Fecha/Hora Cita</label>
                                {/* <TooltipComponent message={'Escoge una fecha y hora para tu solicitud'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <div className="InputTooltipWrapper horasYFechas" >
                                <input className="Input" id="fechaHoraCita" ref={refs.fechaHoraCita} type='text' value={dates.fechaHoraCita} onChange={(e) => setDates({ ...dates, fechaHoraCita: e.target.value })} autoComplete='off' />
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className='InputTooltipWrapper'>
                                <label className="Label" htmlFor="fechaHoraDescargue">Fecha/Hora Descargue</label>
                                {/* <TooltipComponent message={'Escoge una fecha y hora para el descargue'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <div className="InputTooltipWrapper horasYFechas">
                                <input className="Input" id="fechaHoraDescargue" type='text' ref={refs.fechaHoraDescargue} value={dates.fechaHoraDescargue} onChange={(e) => setDates({ ...dates, fechaHoraDescargue: e.target.value })} autoComplete='off' />
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='rowInfo'>
                        <MDBCol md={5}>
                            <div className='InputTooltipWrapper'>
                                <label className="Label" htmlFor="horaTransito">Hora Tránsito</label>
                                {/* <TooltipComponent message={'Escoge la hora de tránsito y selecciona el uso horario, revisa si tiene restricciones y actívalas siendo el caso'} /> */}
                            </div>
                        </MDBCol>
                        <MDBCol md={7}>
                            <div className="InputTooltipWrapper horasYFechas">
                                <input
                                    className="Input"
                                    id="horaTransito1"
                                    ref={refs.horaTransitoStart}
                                    type="text" // Cambiado a 'text' para usar con datetimepicker
                                    value={times.horaTransitoStart}
                                    onChange={(e) => setTimes({ ...times, horaTransitoStart: e.target.value })}
                                    autoComplete='off'
                                />
                                <input
                                    className="Input"
                                    id="horaTransito2"
                                    ref={refs.horaTransitoEnd}
                                    type="text" // Cambiado a 'text' para usar con datetimepicker
                                    value={times.horaTransitoEnd}
                                    onChange={(e) => setTimes({ ...times, horaTransitoEnd: e.target.value })}
                                    autoComplete='off'
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <fieldset id='checkbox' style={{ width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'start', marginBottom: '5px', marginTop: '5px' }}>
                        <div className="checkboxWrapper" >
                            {/* <CheckboxComponent checked={restriccion} onChange={handleCheckboxChange}/> */}
                            <SwitchButton checked={restriccion} onChange={handleCheckboxChange} type={'Restricción'} />
                            <TooltipComponent message={'Con esto activas las restricciones en el horario para la ruta'} />
                        </div>
                    </fieldset>





                    <fieldset className="Fieldset column">
                        <div className='contToltip'>
                            <label className="Label" htmlFor="solicitante">Solicitante</label>
                            {/* <TooltipComponent message={'Nombre del solicitante o persona encargada'} /> */}
                        </div>
                        <div className="InputTooltipWrapper">
                            <input className="Input" id="solicitante" value={solicitante} onChange={(e) => setSolicitante(e.target.value)} />
                        </div>
                    </fieldset>
                    <fieldset className="Fieldset column">
                        <div className='contToltip'>
                            <label className="Label" htmlFor="datosEntrega">Datos de entrega</label>
                            {/* <TooltipComponent message={'Adjunta todos los datos de la entrega en un breve resumen'} /> */}
                        </div>
                        <div className="InputTooltipWrapper">
                            <textarea className="TextArea" id="datosEntrega" value={datosEntrega} onChange={(e) => setDatosEntrega(e.target.value)} />
                        </div>
                    </fieldset>
                    <fieldset className="Fieldset" id='CatServicioFather'>
                        <div className='contToltip'>
                            <label className="Label" htmlFor="categoriaServicio">Categ. Servicio</label>
                            {/* <TooltipComponent message={'Categoría del servicio'} /> */}
                        </div>
                        <div className="InputTooltipWrapper">
                            <select className="InputSelect" id='catServicio' onChange={handleSelectChangeCatServicio} value={selectedCatServicio}>
                                {categoriasServicio.map((catServicio) => (
                                    <option key={catServicio.IdCategoriaServ} value={catServicio.IdCategoriaServ}>
                                        {catServicio.CategoriaServ}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </fieldset>
                    <fieldset className="Fieldset column">
                        <div className='contToltip'>
                            <label className="Label" htmlFor="causalCategoria">Causal por categoría</label>
                            {/* <TooltipComponent message={'Describe el porqué de la elección del tipo de servicio'} /> */}
                        </div>
                        <div className="InputTooltipWrapper">
                            <textarea className="TextArea" id="causalCategoria" value={causalCat} onChange={(e) => setCausalCat(e.target.value)} />
                        </div>
                    </fieldset>

                    <fieldset className="Fieldset column" id="observacionesServicio">
                        <div className='contToltip'>
                            <label className="Label" htmlFor="observacionesServicio">Observaciones del servicio</label>
                            {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                        </div>
                        <div className="InputTooltipWrapper">
                            <textarea className="TextArea" id='observacionesText' value={notas} onChange={(e) => setNotas(e.target.value)} />
                        </div>
                    </fieldset>
                </MDBCol>

            </MDBRow>
            <div className="ButtonWrapper">

                <button className="Button blue" onClick={(e) => { e.preventDefault(); handleSubmit() }}>Grabar</button>


                <button className="Button blue">Enviar</button>


                <button className="Button blue">Busquedas</button>

            </div>
        </div >
    )
}

export default FormSolicitudesLog
