import React from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Css/themes/base/all.css';
import logo from '../logo.svg';
import '../App.css';
import './Css/trafico.css';
import Menu from '../Modulos/Menu';
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import axios from '../utils/axiosConfig';
import Swal from 'sweetalert2';
var table;
var miStorage = window.localStorage;
class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.ListarRoles = this.ListarRoles.bind(this);
    this.state = {
      listRoles: [],
      bitOpen: false,
      bitInsert: false,
      bitEdit: false,
      bitDelete: false,
    };
    this.HandlePageByRole();
    this.ListarRoles();
  }

  componentDidUpdate() {
    $('#search').keyup();

  }
  componentDidMount() {
    $("#success-alert").hide();
    $("#danger-alert").hide();

    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#tabladata tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }

  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarRoles() {
    axios.get(process.env.REACT_APP_SERVER + '/operaciones/getroles', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: 'true'
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listRoles: res.data.data });
        }
      })
  }

  HandlePageByRole = async () => {
    let data = {
      pagina: "11",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true, // this should be inside the options object
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState({
            bitOpen: res.data.data[0].bitOpen,
            bitInsert: res.data.data[0].bitInsert,
            bitEdit: res.data.data[0].bitEdit,
            bitDelete: res.data.data[0].bitDelete,
          }, () => {
            if (this.state.bitOpen) {
              console.warn("Tienes todos los permisos");

              return
            } else {
              Swal.fire({
                title: 'Información',
                text: 'No tienes los permisos necesarios para acceder a esta página',
                icon: 'info',
                timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
                showConfirmButton: false,
                allowOutsideClick: false, // Prevents clicking outside the modal to close it
                didOpen: () => {
                  Swal.showLoading(); // Shows the loading spinner
                },
                timer: 3000 // Time before redirecting (3 seconds)
              }).then(() => {
                // This will execute after the modal finishes showing
                window.location.href = "/principal"; // Redirect to another page
              });
            }

          })
        }

      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };



  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">


            <div className="layout-page">
              <Barra />
              {this.state.bitOpen && (
                <div className="content-wrapper">

                  <div className="container-fluid flex-grow-1 container-p-y">
                    <MDBRow>
                      <MDBCol md='7'>
                        <h4 className="fw-bold mb-4">Roles</h4>
                      </MDBCol>
                      <MDBCol md='4'>
                        <div className="input-group col-md-12">
                          <span className="input-group-text" id="b1"><FaSearch /></span>
                          <input id="search" type="text" className="form-control" placeholder="Filtrar" aria-describedby="b1"
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md='1'>
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd"
                        >
                          <FaFilter />
                        </button>
                      </MDBCol>
                    </MDBRow>

                    <div className="card">
                      <div className="table-responsive text-nowrap">
                        <table className="table table-hover" id="tabla">
                          <thead className="FondoHeaderTabla2">
                            <tr>
                              <th style={{ color: '#ffffff' }}>IDROL</th>
                              <th style={{ color: '#ffffff' }}>NOMBRE</th>
                              <th style={{ color: '#ffffff' }}>OPCIONES</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0" id="tabladata">
                            {this.loadFillData(this.state.listRoles)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <footer className="content-footer footer bg-footer-theme">
                    <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                      <div className="mb-2 mb-md-0">
                        ©, Producto realizado por <a href="https://logiseguridad.com" target="_blank" className="footer-link fw-bolder"> Logiseguridad Ltda</a>
                      </div>

                    </div>
                  </footer>

                  <div className="content-backdrop fade"></div>
                </div>
              )}
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la información.
        </div>



      </>


    );
  }

  loadFillData(array) {


    return this.state.listRoles.map((data) => {

      return (
        <tr key={data.IDRol}>
          <td className="prueba"><strong>{data.IDRol}</strong></td>
          <td>{data.NombreRol}</td>
          <td>
            <div className="dropdown position-static">
              <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                <i className="bx bx-dots-vertical position-static"></i>
              </button>
              <div className="dropdown-menu">
                <span onClick={() => this.OpenEdit(data)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                  <i className="bx bx-edit me-1"></i> Editar
                </span>
                <span onClick={() => this.OpenPermisos(data)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                  <i className="bx bx-file me-1"></i> Ver Permisos
                </span>
              </div>
            </div>
          </td>

        </tr>
      )
    });
    $('.dropdown-toggle').dropdown();

  }
}

export default Roles;
